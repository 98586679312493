import { ROUTES } from '@constants/constants';
import useStore from '@hooks/useStore';
import { EventPage } from '@type-declarations/page';
import { Event, WithContext } from 'schema-dts';

export default function EventSchema({ page }: { page: EventPage }) {
  const {
    store: { locale },
  } = useStore();

  const adrs = page.address?.street
    ? page.address
    : page.location?.[0]?.address;

  const schema: WithContext<Event> = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: page.title,
    description: page.introText,
    url: `${process.env.ENVIRONMENT_URL + ROUTES.EventOverviewPage[locale]}/${
      page.slug
    }`,
    eventStatus: 'https://schema.org/EventScheduled',
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    image: page.image?.url,
    organizer: {
      '@type': 'Organization',
      name: page.address?.title || page.location?.[0]?.address?.title,
      image: page.location?.[0]?.image?.url,
      url: page.website,
      telephone: page.phone,
      email: page.mail,
    },
  };

  if (page.price) {
    schema.offers = {
      '@type': 'Offer',
      price: page.price,
      validFrom: page.dates?.[0]?.from,
      availability: 'https://schema.org/InStock',
      url: page.website,
      priceCurrency: 'EUR',
    };
  }

  if (page.dates?.length) {
    schema.startDate = page.dates[0].from;
    schema.endDate = page.dates.at(-1)!.until;
  }

  schema.location = {
    '@type': 'Place',
    name: adrs?.title,
    geo: {
      '@type': 'GeoCoordinates',
      latitude:
        page.coordinates?.latitude || page.location?.[0]?.coordinates?.latitude,
      longitude:
        page.coordinates?.longitude ||
        page.location?.[0]?.coordinates?.longitude,
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: adrs?.street,
      addressLocality: adrs?.city,
      postalCode: adrs?.zipcode,
      addressCountry: adrs?.country,
    },
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
}
