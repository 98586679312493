import useFilters from '@hooks/useFilters';
import useStoreBookmarks from '@hooks/useStoreBookmarks';
import FilterBar from '@molecules/Filters/FilterBar';
import Filters from '@molecules/Filters/Filters';
import Hero from '@organisms/Hero/Hero';
import InteractiveMap from '@organisms/InteractiveMap/InteractiveMap';
import Sections from '@organisms/Sections/Sections';
import { FilterGroup } from '@type-declarations/filters';
import { MapPage } from '@type-declarations/page';
import { groupCategoryFilters } from '@utils/filters/categoryFilter';
import { useMemo } from 'react';

function MapTemplate({ page }: { page: MapPage }) {
  const {
    id,
    title,
    introText,
    image,
    alignment,
    sections,
    items: initialItems,
  } = page;

  const { bookmarks } = useStoreBookmarks();

  const { filters, handleChange, clearFilters } = useFilters({});

  const items = useMemo(
    () =>
      initialItems
        .filter(item => {
          /* If bookmarks filter is active, show bookmarked items */
          // if (filters.bookmarked && !bookmarks.includes(item.id)) return false;

          /* If no filters are active show all */
          if (!filters.categories.length) return true;

          /* Check if item has sub category */
          if (item.category?.[0]?.typename !== 'SubSubCategory') return false;

          /* Filter items that match category filters */
          return (
            filters.categories.findIndex(filter =>
              item.category?.find(category => category.slug === filter)
            ) > -1
          );
        })
        .map(location => ({
          ...location,
          icon:
            location.category?.[0]?.icon ||
            location.category?.[0]?.category?.[0]?.icon,
          pos: [
            Number(location.coordinates.latitude),
            Number(location.coordinates.longitude),
          ],
        })),
    [initialItems, filters.categories]
  );

  const categoryGroups: FilterGroup[] = useMemo(
    () => groupCategoryFilters(page.categories, initialItems),
    [page.categories, initialItems]
  );

  return (
    <>
      <Hero
        pageId={id}
        title={title}
        text={introText}
        image={image}
        variant="small"
        colorVariant="gray"
        alignment={alignment}
      />

      <Filters
        primary={[]}
        secondary={categoryGroups}
        activeFilters={filters}
        onChange={handleChange}
        onClear={clearFilters}
      />

      <FilterBar
        active={filters.bookmarked ? 'bookmarked' : 'all'}
        onChange={filter =>
          handleChange({ type: 'bookmarked', value: filter === 'bookmarked' })
        }
      />

      <InteractiveMap
        size="large"
        maxClusterRadius={70}
        locations={
          filters.bookmarked
            ? items.filter(item => bookmarks.includes(item.id))
            : items
        }
      />
      <Sections sections={sections} />
    </>
  );
}

export default MapTemplate;
