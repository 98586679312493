import useSharedBookmarks from '@hooks/useSharedBookmarks';
import CardsGridWrapper from '@molecules/CardsGridWrapper/CardsGridWrapper';
import Loader from '@molecules/Loader/Loader';
import Hero from '@organisms/Hero/Hero';
import { SharedListPage } from '@type-declarations/page';

interface Props {
  page: SharedListPage;
}

export default function SharedListTemplate({ page }: Props) {
  const { title, introText, image, alignment } = page;
  const { items, total, loading } = useSharedBookmarks();

  return (
    <>
      <Hero
        title={title}
        text={introText}
        image={image}
        colorVariant="gray"
        alignment={alignment}
      />

      {loading ? (
        <Loader loading={loading} modifier="verticalPadding" />
      ) : (
        <CardsGridWrapper items={items || []} total={total} showType />
      )}
    </>
  );
}
