import { useEffect, useRef } from 'react';

export default function useDebounce<T>(
  callback: (args: T) => void,
  delay: number
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(
    () =>
      // Cleanup the previous timeout on re-render
      () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      },
    []
  );

  const debouncedCallback = (args: T) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(args);
    }, delay);
  };

  return debouncedCallback;
}
