import { addressFields } from '@lib/fragments/address-fragment';
import assetFragment from '@lib/fragments/asset-fragment';
import { localizationsFields } from '@lib/fragments/localizations-fragment';
import {
  sectionsFields,
  sectionsFragment,
} from '@lib/fragments/sections-fragment';
import { seoFields } from '@lib/fragments/seo-fragment';
import gql from 'graphql-tag';

const ROUTE_PAGE_QUERY = gql`
  query ROUTE_PAGE_QUERY($slug: String, $locale: String) {
    RoutePage(slug: $slug, locale: $locale) {
      typename: __typename
      id: _id
      slug: _slug
      locales: _locales
      publishOn: _publish_on
      title
      introText: intro_text
      image {
        ...assetFields
        url(width: 1920, format: "webp")
      }
      alignment { 
        focusPoint: focus_point
      }
      heroColor: hero_color {
        color
      }
      # SubSub
      category {
        heroColor: hero_color {
          color
        }
        # Sub
        category {
          colorPalette: color_palette
          heroColor: hero_color {
            color
          }
          # Main
          category {
            colorPalette: color_palette
            heroColor: hero_color {
              color
            }
          }
        }
      }
      gpx {
        url
      }
      pdf {
        url
      }
      distance
		  duration
      description
      routeType: route_type
      start {
        ${addressFields}
      }
      startLocation: start_location {
        latitude
        longitude
      }
      end {
        ${addressFields}
      }
      endLocation: end_location {
        latitude
        longitude
      }
      stops { 
        id: _id
        instructions
        image {
          ...assetFields
          # image queried twice the size for quality
          url(width: 1200, quality: 100, format: "webp")
        }
        location {
          id: _id
          title
          slug: _slug
          address {
            ${addressFields}
          }
          coordinates {
            latitude
            longitude
          }
          image {
            ...assetFields
            # image queried twice the size for quality
            url(width: 1200, quality: 100, format: "webp")
          }
          category {
            icon
            category {
              icon
            }
          }
        }
      }

      ${localizationsFields}
      ${sectionsFields}
      ${seoFields}
    }
  }
  ${assetFragment}
  ${sectionsFragment}
`;

export default ROUTE_PAGE_QUERY;
