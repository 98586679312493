import { FilterGroup } from '@type-declarations/filters';
import { OverviewItem } from '@type-declarations/overviewItems';
import { CategoryFilter } from '@type-declarations/prepr';

export const groupCategoryFilters = (
  categories: CategoryFilter[],
  items?: OverviewItem[]
): FilterGroup[] => {
  const mainCategories: CategoryFilter[] = [];
  const subCategories: CategoryFilter[] = [];

  categories.forEach(category => {
    if (!category.category?.length || !category.category[0].category?.length) {
      return;
    }

    const subCategory = category.category[0];
    if (
      !subCategories.find(comparison => comparison.slug === subCategory.slug)
    ) {
      subCategories.push(subCategory);
    }

    const mainCategory = category.category[0].category[0];
    if (
      !mainCategories.find(comparison => comparison.slug === mainCategory.slug)
    ) {
      mainCategories.push(mainCategory);
    }
  });

  return mainCategories
    .sort((a, b) => a.title.localeCompare(b.title))
    .map(mainCategory => ({
      title: mainCategory.title,
      key: 'categories',
      filters: subCategories
        .filter(
          subCategory =>
            subCategory.category?.length &&
            subCategory.category[0].slug === mainCategory.slug
        )
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(subCategory => ({
          value: subCategory.slug,
          title: subCategory.title,
          filters: categories
            .filter(category => {
              if (
                !category.category?.length ||
                category.category[0].slug !== subCategory.slug
              ) {
                return false;
              }

              if (items) {
                const hasResults =
                  items.findIndex(item => {
                    if (!item.category?.length) return false;

                    return (
                      item.category?.findIndex(
                        cat => cat.slug === category.slug
                      ) > -1
                    );
                  }) > -1;

                return hasResults;
              }

              return true;
            })
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(category => ({
              value: category.slug,
              title: category.title,
            })),
        }))
        .filter(subCategory => subCategory.filters.length),
    }));
};
