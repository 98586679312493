import RichText from '@atoms/RichText/RichText';
import { Table, Tbody, Td, Tr } from '@atoms/Table/Table';
import { DAYS } from '@constants/constants';
import useTranslations from '@hooks/useTranslations';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import formatDate from '@utils/formatDate';

import styles from './OpeningTimesTable.module.scss';

type SpecialHours = {
  openDay: (typeof DAYS)[number];
  closeDay: (typeof DAYS)[number];
  openTime: string;
  closeTime: string;
  validFrom: string;
  validUntil: string;
  isClosed: boolean;
};

type RegularHours = {
  openDay: (typeof DAYS)[number];
  closeDay: (typeof DAYS)[number];
  openTime: string;
  closeTime: string;
};

interface Props {
  regularHours: RegularHours[];
  specialHours?: SpecialHours[];
  message?: string;
}

export function getTime(
  day: string,
  regularHours: RegularHours[],
  translations: ReturnType<typeof useTranslations>
): JSX.Element[] | string {
  const hours = regularHours.filter(({ openDay }) => openDay === day);

  // Returns closed when hours of specific day are not defined
  if (!hours.length) {
    return translations.closed;
  }

  return hours.map(({ openTime, closeTime }) => (
    <span
      key={openTime + closeTime}
      className={styles.hours}
    >{`${openTime} - ${closeTime} ${translations.hour.toLowerCase()}`}</span>
  ));
}

export default function OpeningTimesTable({
  regularHours,
  specialHours,
  message,
}: Props) {
  const translations = useTranslations();

  function getDates({ openDay, validFrom, validUntil }: SpecialHours) {
    if (validFrom === validUntil) {
      return formatDate({
        date: validFrom,
        format: 'dd-MM-Y',
      });
    }

    const formattedFromDate = formatDate({
      date: validFrom,
      format: 'dd-MM-Y',
    });
    const formattedUntilDate = formatDate({
      date: validUntil,
      format: 'dd-MM-Y',
    });
    return `${formattedFromDate} t/m ${formattedUntilDate}: ${
      translations.each
    } ${translations[openDay].toLowerCase()}`;
  }

  function getExeptionalTime({ openTime, closeTime, isClosed }: SpecialHours) {
    if (isClosed) {
      return translations.closed;
    }

    if (openTime === '00:00' && closeTime === '00:00') {
      return translations.allDayOpen;
    }
    return `${openTime} - ${closeTime}`;
  }

  return (
    <Section>
      <SectionContent title={translations.openingHours} centered />
      <Table>
        <Tbody>
          {DAYS.map(day => (
            <Tr key={day}>
              <Td>
                {translations.each} {translations[day].toLowerCase()}
              </Td>
              <Td>{getTime(day, regularHours, translations)}</Td>
            </Tr>
          ))}
          {!!specialHours?.length && (
            <>
              <Tr>
                <Td colSpan={2} className={styles.exception}>
                  {translations.exception}
                </Td>
              </Tr>
              {specialHours.map(row => (
                <Tr key={row.validFrom}>
                  <Td>{getDates(row)}</Td>
                  <Td>{getExeptionalTime(row)}</Td>
                </Tr>
              ))}
            </>
          )}
          {!!message && (
            <Tr>
              <Td colSpan={2}>
                <RichText text={message} />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Section>
  );
}
