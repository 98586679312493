import { accessibilityFields } from '@lib/fragments/accessibility';
import { addressFields } from '@lib/fragments/address-fragment';
import assetFragment from '@lib/fragments/asset-fragment';
import ctaFragment from '@lib/fragments/cta-fragment';
import { localizationsFields } from '@lib/fragments/localizations-fragment';
import {
  sectionsFields,
  sectionsFragment,
} from '@lib/fragments/sections-fragment';
import { seoFields } from '@lib/fragments/seo-fragment';
import { tagFragment } from '@lib/fragments/tag-fragment';
import gql from 'graphql-tag';

const LOCATION_PAGE_QUERY = gql`
  query LOCATION_PAGE_QUERY($slug: String, $locale: String) {
    LocationPage(slug: $slug, locale: $locale) {
      typename: __typename
      id: _id
      slug: _slug
      locales: _locales
      title
      introText: intro_text
      tags: content_tags {
        ${tagFragment}
      }
      image {
        ...assetFields
        url(width: 1920, format: "webp")
      }
      alignment { 
        focusPoint: focus_point
      }
      heroColor: hero_color {
        color
      }
      youtubeId: youtube_id
      extraImages: extra_images {
        ...assetFields
        url(width: 800, height: 500, format: "webp")
      }
      # SubSub
      category {
        heroColor: hero_color {
          color
        }
        # Sub
        category {
          colorPalette: color_palette
          heroColor: hero_color {
            color
          }
          # Main
          category {
            colorPalette: color_palette
            heroColor: hero_color {
              color
            }
          }
        }
      }
      mail
      phone
      website
      openingHoursMessage: opening_hours_message
      openingHours: opening_hours {
        regularHours: regular_hours {
          openDay: open_day
          openTime: open_time
          closeDay: close_day
          closeTime: close_time
        }
        specialHours: special_hours {
          openDay: open_day
          openTime: open_time
          closeDay: close_day
          closeTime: close_time
          validFrom: valid_from
          validUntil: valid_until
          isClosed: is_closed
        }
      }
      description
      ${accessibilityFields}
      coordinates {
        latitude
        longitude
      }
      address {
        ${addressFields}
      }
      ${localizationsFields}
      ${sectionsFields}
      ${seoFields}
    }
  }
  ${assetFragment}
  ${sectionsFragment}
  ${ctaFragment}
`;

export default LOCATION_PAGE_QUERY;
