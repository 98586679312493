import Icon from '@atoms/Icon/Icon';
import CtaButton from '@molecules/CtaButton/CtaButton';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { HighlightedBannerSection as Props } from '@type-declarations/sections';
import clsx from 'clsx';

import styles from './HighlightedBannerSection.module.scss';

function HighlightedBannerSection({
  icon,
  bannerTitle,
  bannerText,
  theme,
  bannerCta,
}: Props) {
  return (
    <Section>
      <div className={clsx(styles.wrapper, theme && styles[theme])}>
        {icon && <Icon className={styles.icon} icon={icon} />}
        <SectionContent
          className={styles.content}
          title={bannerTitle}
          text={bannerText}
        />
        {bannerCta && (
          <div className={styles.ctaContainer}>
            <CtaButton cta={bannerCta} variant="brand" />
          </div>
        )}
      </div>
    </Section>
  );
}

export default HighlightedBannerSection;
