import Icon from '@atoms/Icon/Icon';
import useTranslations from '@hooks/useTranslations';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import CheckboxInput from './CheckboxInput';
import styles from './CheckboxInput.module.scss';

export interface IndeterminateCheckboxEventOption {
  id: string;
  checked: boolean;
}

type IndeterminateCheckboxEvent = (
  options: IndeterminateCheckboxEventOption[]
) => void;

interface Props {
  id: string;
  name: string;
  label: string;
  value: string;
  onChange: IndeterminateCheckboxEvent;
  checkboxes: {
    id: string;
    name: string;
    label: string;
    value: string;
    checked: boolean;
  }[];
  hideOptions: boolean;
}

export default function IndeterminateCheckboxInput({
  id,
  name,
  label,
  value,
  onChange,
  checkboxes,
  hideOptions,
}: Props) {
  const t = useTranslations();
  const indeterminateCheckboxRef = useRef<HTMLInputElement>(null!);
  const groupRef = useRef<HTMLDivElement>(null);

  const [activeCheckboxes, setActiveCheckboxes] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [itemHeight, setItemHeight] = useState<string>('0');

  useEffect(() => {
    setActiveCheckboxes(
      checkboxes
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.id)
    );
  }, [checkboxes]);

  useEffect(() => {
    setItemHeight(`${groupRef?.current?.offsetHeight || 0}px`);
  }, [isExpanded]);

  const handleIndeterminateChange = () => {
    if (activeCheckboxes.length === checkboxes.length) {
      onChange(
        checkboxes.map(checkbox => ({ id: checkbox.id, checked: false }))
      );
      return;
    }

    onChange(checkboxes.map(checkbox => ({ id: checkbox.id, checked: true })));
  };

  const handleCheckboxChange = (checkboxId: string) => {
    const newCheckboxes = activeCheckboxes.includes(checkboxId)
      ? activeCheckboxes.filter(
          activeCheckboxId => activeCheckboxId !== checkboxId
        )
      : [...activeCheckboxes, checkboxId];

    onChange(
      checkboxes.map(checkbox => ({
        id: checkbox.id,
        checked: newCheckboxes.includes(checkbox.id),
      }))
    );
  };

  const handleClick = () => {
    setIsExpanded(state => !state);
  };

  const style = { '--item-height': itemHeight } as React.CSSProperties;

  return (
    <div>
      <div className={styles.indeterminateWrapper}>
        <CheckboxInput
          id={`indeterminate-checkbox-${id}`}
          name={name}
          label={label}
          value={value}
          checked={!!activeCheckboxes.length}
          indeterminate={
            activeCheckboxes.length < checkboxes.length &&
            !!activeCheckboxes.length
          }
          onChange={handleIndeterminateChange}
          ref={indeterminateCheckboxRef}
        />

        {!hideOptions && (
          <button
            type="button"
            onClick={handleClick}
            className={styles.expandButton}
            aria-label={isExpanded ? t.collapse : t.expand}
          >
            <Icon
              className={clsx(styles.chevron, isExpanded && styles.expanded)}
              icon="NAV_ARROW_DOWN"
            />
          </button>
        )}
      </div>

      <div id={`${id}-panel`} style={style}>
        <CSSTransition
          in={isExpanded}
          timeout={300}
          unmountOnExit
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
        >
          <div className={styles.group}>
            <div className={styles.groupContent} ref={groupRef}>
              {checkboxes.map(checkbox => (
                <CheckboxInput
                  key={`indeterminate-checkbox-child-${checkbox.id}`}
                  id={`indeterminate-checkbox-child-${checkbox.id}`}
                  name={checkbox.name}
                  label={checkbox.label}
                  value={checkbox.value}
                  checked={activeCheckboxes.includes(checkbox.id)}
                  onChange={() => handleCheckboxChange(checkbox.id)}
                />
              ))}
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}
