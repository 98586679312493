import { Tag as TagType } from '@type-declarations/prepr';
import clsx from 'clsx';

import Tag from './Tag';
import styles from './Tags.module.scss';

interface Props {
  tags: TagType[];
  className?: string;
  variant?: 'sm' | 'md';
}

export default function Tags({ tags, className, variant }: Props) {
  return (
    <ul className={clsx(styles.tags, 'u-list-clean', className)}>
      {tags.map(({ slug, title }) => (
        <li key={slug}>
          <Tag title={title} variant={variant} />
        </li>
      ))}
    </ul>
  );
}
