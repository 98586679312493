import { isRangeFilter, RangeFilter } from '@type-declarations/filter';

function isEqualDistance(
  firstDistance: RangeFilter,
  secondDistance: RangeFilter
) {
  return (
    firstDistance[0] === secondDistance[0] &&
    firstDistance[1] === secondDistance[1]
  );
}

export function isActiveDistanceFilter(
  distance: RangeFilter,
  comparison: string
) {
  const range = comparison.split('-').map(value => +value);
  if (!isRangeFilter(range)) return false;

  return isEqualDistance(distance, range);
}

export function convertToDistanceFilter(
  value: string,
  current: RangeFilter | null
): RangeFilter | null {
  const range = value.split('-').map(distance => +distance);
  if (!isRangeFilter(range)) return null;

  if (current && isEqualDistance(current, range)) {
    return null;
  }

  return range;
}

export function normalizeDistanceFilter(
  distance: string | string[] | undefined
): RangeFilter | null {
  if (!distance || typeof distance === 'string' || distance.length !== 2)
    return null;

  return [+distance[0], +distance[1]];
}

/**
 * RangeFilter | null | undefined when using client side filtering
 * string | string[] when using client side filtering
 */
export function prepareDistanceForQuery(
  distance: RangeFilter | null | undefined | string | string[]
) {
  if (!distance || typeof distance === 'string' || distance.length !== 2) {
    return {};
  }

  return {
    distanceGte: +distance[0],
    distanceLte: +distance[1],
  };
}
