import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(useGSAP, ScrollTrigger);

interface ScrollTriggerProps {
  ref: React.RefObject<HTMLDivElement>;
  animation: (props: {
    ref: React.RefObject<HTMLDivElement>;
    gsap: typeof gsap;
  }) => void;
}

function useScrollTrigger({ ref, animation }: ScrollTriggerProps) {
  useGSAP(() => animation({ ref, gsap }));
}

export default useScrollTrigger;
