import clsx from 'clsx';
import { PropsWithChildren, TdHTMLAttributes } from 'react';

import styles from './Table.module.scss';

export function Table({ children }: PropsWithChildren) {
  return <table className={styles.table}>{children}</table>;
}

export function Tbody({ children }: PropsWithChildren) {
  return <tbody className={styles.tbody}>{children}</tbody>;
}

export function Tr({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <tr className={clsx(styles.tr, className)}>{children}</tr>;
}

export function Td({
  children,
  colSpan,
  className,
  align,
}: TdHTMLAttributes<PropsWithChildren>) {
  return (
    <td align={align} className={clsx(styles.td, className)} colSpan={colSpan}>
      {children}
    </td>
  );
}
