import AlertBar from '@atoms/AlertBar/AlertBar';
import Figure from '@atoms/Figure/Figure';
import RichText from '@atoms/RichText/RichText';
import SaveButton from '@atoms/SaveButton/SaveButton';
import ShareButton from '@atoms/ShareButton/ShareButton';
import Tags from '@atoms/Tags/Tags';
import HeroVideo from '@atoms/Video/HeroVideo';
import useStore from '@hooks/useStore';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import CtaButton from '@molecules/CtaButton/CtaButton';
import { ButtonVariants, Cta } from '@type-declarations/cta';
import type { HeroColorVariants } from '@type-declarations/hero';
import { Image as ImageType, ImageAlignment } from '@type-declarations/media';
import { Tag } from '@type-declarations/prepr';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './Hero.module.scss';

interface Props {
  pageId?: string;
  cta?: Cta;
  secondCta?: Cta;
  ctaBottom?: Cta;
  tags?: Tag[];
  title: string;
  text?: string;
  image?: ImageType;
  video?: {
    mobile: string;
    desktop: string;
  };
  variant?: 'small' | 'large';
  containerSize?: 'regular' | 'narrow';
  colorVariant?: HeroColorVariants;
  warning?: string;
  showTopBar?: boolean;
  hideShareButton?: boolean;
  alignment?: ImageAlignment;
}

const buttonMapping: { [key in HeroColorVariants]: ButtonVariants } = {
  brand: 'primary',
  primary: 'white',
  secondary: 'primary',
  gray: 'primary',
};

export default function Hero({
  pageId,
  cta,
  secondCta,
  ctaBottom,
  text,
  tags,
  title,
  image,
  video,
  variant = 'small',
  containerSize = 'regular',
  colorVariant = 'brand',
  children,
  warning,
  showTopBar,
  hideShareButton,
  alignment,
}: PropsWithChildren<Props>) {
  const {
    store: { page },
  } = useStore();

  return (
    <div
      className={clsx(
        styles.hero,
        styles[variant],
        styles[colorVariant],
        !image && styles.noImage
      )}
    >
      {image && video && (
        <HeroVideo
          className={styles.mediaContainer}
          mobile={video.mobile}
          desktop={video.desktop}
          image={image}
          alignment={alignment}
        />
      )}

      {/* Conditional image */}
      {image?.url && !video && (
        <Figure
          image={image}
          alt={image.caption}
          author={image.author}
          layout="fill"
          objectFit="cover"
          objectPosition={alignment?.focusPoint || 'center center'}
          className={styles.mediaContainer}
          quality={100}
          priority
        />
      )}

      {warning && <AlertBar text={warning} backgroundColor="brand" />}

      <div className={clsx(styles.content, styles[containerSize])}>
        {!!showTopBar && (
          <div className="u-container">
            <div className={styles.topBar}>
              <Breadcrumbs />
              <div className={styles.buttonsContainer}>
                {!hideShareButton && (
                  <ShareButton
                    hideLabel
                    className={styles.shareButton}
                    title={title}
                  />
                )}
                {pageId && (
                  <SaveButton
                    id={pageId}
                    pageTitle={title}
                    pageType={page?.typename}
                    variant="brand"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className={styles.container}>
          <div className={styles.wrapper}>
            <h1 className={clsx(styles.title, 'u-typography-heading-1')}>
              {title}
            </h1>
            {tags && <Tags tags={tags} className={styles.tags} variant="sm" />}
            {text && <RichText className={styles.text} text={text} />}
            {cta && (
              <div className={styles.ctaWrapper}>
                <CtaButton
                  className={styles.cta}
                  cta={cta}
                  variant={buttonMapping[colorVariant]}
                />

                {secondCta && (
                  <CtaButton
                    className={styles.cta}
                    cta={secondCta}
                    variant="text"
                  />
                )}
              </div>
            )}

            {/* Children for custom page-specific content */}
            {!!children && <div className={styles.children}>{children}</div>}

            {ctaBottom && (
              <CtaButton
                className={styles.cta}
                cta={ctaBottom}
                variant={buttonMapping[colorVariant]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
