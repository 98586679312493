import { ROUTES } from '@constants/constants';
import getInternalRoute from '@utils/getInternalRoute';

import useStore from './useStore';

interface Breadcrumb {
  route: string;
  label: string;
}

interface Translatable {
  nl: string;
  en: string;
}

const routes: { [key: string]: { route: Translatable; label: Translatable } } =
  {
    LocationPage: {
      route: ROUTES.LocationOverviewPage,
      label: {
        nl: 'Terug naar locaties',
        en: 'Back to locations',
      },
    },
    EventPage: {
      route: ROUTES.EventOverviewPage,
      label: {
        nl: 'Terug naar evenementen',
        en: 'Back to events',
      },
    },
    NewsPage: {
      route: ROUTES.NewsOverviewPage,
      label: {
        nl: 'Terug naar artikelen',
        en: 'Back to articles',
      },
    },
    RoutePage: {
      route: ROUTES.RouteOverviewPage,
      label: {
        nl: 'Terug naar routes',
        en: 'Back to routes',
      },
    },
    other: {
      route: {
        nl: '/',
        en: '/',
      },
      label: {
        nl: 'Terug naar home',
        en: 'Back to home',
      },
    },
  };

export default function useBreadcrumbs(): Breadcrumb | null {
  const {
    store: { page, locale },
  } = useStore();

  if (!page) return null;

  const route = routes[page.typename]?.route[locale];
  const label = routes[page.typename]?.label[locale];

  if (route && label) return { route, label };

  if (page.category?.[0]) {
    return {
      route: getInternalRoute({ internalLink: page.category[0], locale }),
      label: `Terug naar ${page.category[0].title.toLowerCase()}`,
    };
  }

  return {
    route: routes.other.route[locale],
    label: routes.other.label[locale],
  };
}
