import { Page } from '@type-declarations/page';

interface Props {
  page: Page;
}

export const getHeroColor = ({ page }: Props) => {
  /* If valid option is selected, return value */
  if ('heroColor' in page && page.heroColor?.color) {
    return page.heroColor.color;
  }

  if (
    ['EventPage', 'LocationPage', 'NewsPage', 'RoutePage'].includes(
      page.typename
    )
  ) {
    return 'gray';
  }

  /* Check if parent category has a color selected, get parent styling */
  if ('category' in page) {
    if (page.category?.[0]?.heroColor?.color) {
      return page.category[0].heroColor.color;
    }

    if (page.category?.[0]?.category?.[0]?.heroColor?.color) {
      return page.category[0]?.category?.[0]?.heroColor.color;
    }

    if (page.category?.[0]?.category?.[0]?.category?.[0]?.heroColor?.color) {
      return page.category[0]?.category?.[0]?.category?.[0]?.heroColor.color;
    }
  }

  /* Use gray color as fallback if no color is selected */
  return 'gray';
};
