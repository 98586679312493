import Button from '@atoms/Button/Button';
import useTranslations from '@hooks/useTranslations';
import handleShare from '@utils/handleShare';

interface Props {
  title?: string;
  className?: string;
  hideLabel?: boolean;
}

export default function ShareButton({ title, className, hideLabel }: Props) {
  const { share, urlCopied, sharedTitle, sharedText } = useTranslations();
  const linkTitle = title || sharedTitle;

  return (
    <Button
      className={className}
      label={share}
      onClick={() =>
        handleShare({ urlCopied, title: linkTitle, text: sharedText })
      }
      iconLeft="SHARE_IOS"
      variant="white"
      hideLabel={hideLabel}
      rounded={hideLabel}
    />
  );
}
