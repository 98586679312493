import Icon from '@atoms/Icon/Icon';
import PinIcon from '@svg/route-pin.svg';
import { Address } from '@type-declarations/location';
import { formatAddress } from '@utils/formatAddress';
import clsx from 'clsx';

import styles from './RouteEndItem.module.scss';

interface Props {
  address?: Address;
  title: string;
}

export default function RouteEndItem({ address, title }: Props) {
  return (
    <li className={styles.item}>
      <PinIcon className={styles.pin} />
      <Icon className={styles.icon} icon="WHITE_FLAG" />

      <div className={styles.itemContent}>
        <span className={clsx(styles.itemTitle, 'u-typography-paragraph-lg')}>
          {title}
        </span>
        {address && (
          <span className="u-typography-paragraph-md">
            {formatAddress(address)}
          </span>
        )}
      </div>
    </li>
  );
}
