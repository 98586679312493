import useStore from '@hooks/useStore';
import Section from '@molecules/Section/Section';
import { useEffect, useRef } from 'react';

import styles from './CookieDeclarationSection.module.scss';

export default function CookieDeclarationSection() {
  const wrapper = useRef<HTMLDivElement>(null!);
  const {
    store: { locale },
  } = useStore();

  useEffect(() => {
    const id = process.env.COOKIEBOT_ID;
    if (!id) return;

    const script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.dataset.culture = locale;
    script.src = `https://consent.cookiebot.com/${id}/cd.js`;
    script.type = 'text/javascript';
    script.async = true;

    wrapper.current.appendChild(script);
  }, [locale]);

  return (
    <Section>
      <div ref={wrapper} className={styles.wrapper} />
    </Section>
  );
}
