import assetFragment from '@lib/fragments/asset-fragment';
import ctaFragment from '@lib/fragments/cta-fragment';
import gql from 'graphql-tag';

const imageSectionFragment = gql`
  fragment imageSectionFields on ImageSection {
    title
    subtitle
    text
    cta {
      ...ctaFields
    }
    image {
      ...assetFields
      url(width: 1296, height: 830, format: "webp")
    }
    imageAlt: alt_text
    backgroundColor: background_color {
      color
    }
    fullWidth: full_width
  }
  ${assetFragment}
  ${ctaFragment}
`;

export default imageSectionFragment;
