import { fetchPrepr } from '@lib/prepr/prepr';
import ROUTE_PAGE_QUERY from '@lib/queries/routePage';
import ROUTE_PAGES_QUERY from '@lib/queries/routePages';
import { Route } from '@type-declarations/overviewItems';
import { RoutePage } from '@type-declarations/page';
import { QueryParameters } from '@type-declarations/prepr';
import { prepareDistanceForQuery } from '@utils/filters/distanceFilter';

/*
  Route page
*/
export interface RoutePagesResponse {
  data: {
    RoutePages: {
      items: Route[];
      total: number;
    };
  };
}

export interface RoutePageResponse {
  data: {
    RoutePage: RoutePage;
  };
}

export async function getRoutePage({ slug, locale, preview }: QueryParameters) {
  const res = await fetchPrepr<RoutePageResponse>({
    query: ROUTE_PAGE_QUERY,
    variables: { slug, locale, preview },
  });
  return res?.data?.RoutePage;
}

/* Event pages */
export async function getRoutePages({
  page,
  locale,
  preview,
  title,
  tags,
  categories,
  distance,
}: QueryParameters) {
  const res = await fetchPrepr<RoutePagesResponse>({
    query: ROUTE_PAGES_QUERY({
      filterCategories: !!categories?.length,
      filterTags: !!tags?.length,
      filterTitle: !!title,
    }),
    variables: {
      page,
      locale,
      preview,
      title,
      tags,
      categories,
      ...prepareDistanceForQuery(distance),
    },
  });

  return {
    items: res?.data?.RoutePages.items || [],
    total: res?.data?.RoutePages.total || 0,
  };
}
