import { translations } from '@hooks/useTranslations';
import { Locale } from '@type-declarations/locale';

/* Format duration in minutes */
export default function formatDuration(
  duration: number,
  locale: Locale
): string {
  if (duration > 60) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const hr = translations[locale].hour.toLowerCase();

    if (minutes === 0) {
      return `${hours} ${hr}`;
    }

    return `${hours} ${hr} ${minutes} min`;
  }

  return `${duration} min`;
}
