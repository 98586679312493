import Icon from '@atoms/Icon/Icon';
import clsx from 'clsx';
import { ChangeEventHandler, forwardRef } from 'react';

import styles from './CheckboxInput.module.scss';

interface Props {
  id: string;
  name: string;
  label: string;
  value: string;
  checked: boolean;
  indeterminate?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const CheckboxInput = forwardRef<HTMLInputElement, Props>(
  (
    { id, name, label, value, checked, indeterminate, onChange }: Props,
    ref
  ) => (
    <div className={styles.checkbox}>
      <input
        className={clsx(styles.input, 'u-visually-hidden')}
        type="checkbox"
        name={name}
        value={value}
        id={id}
        checked={checked}
        onChange={onChange}
        ref={ref}
      />

      <label htmlFor={id} className={styles.labelWrapper}>
        <span className={styles.indicator}>
          <Icon
            icon={indeterminate ? 'MINUS' : 'CHECK'}
            className={styles.icon}
          />
        </span>
        <span className={styles.label}>{label}</span>
      </label>
    </div>
  )
);

CheckboxInput.displayName = 'CheckboxInput';

export default CheckboxInput;
