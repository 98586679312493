import gql from 'graphql-tag';

const newsletterSectionFragment = gql`
  fragment newsletterSectionFields on NewsletterSection {
    id: _id
    title
    text
    containerColor: container_color
    backgroundColor: background_color {
      color
    }
    formId: form_id
  }
`;

export default newsletterSectionFragment;
