import Button from '@atoms/Button/Button';
import { A11Y_PANEL_ID } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';

import styles from './AccessibilityButton.module.scss';

export default function AccessibilityButton() {
  const {
    store: { showAccessibilityPanel },
    setStore,
  } = useStore();

  const { adjustA11y } = useTranslations();

  return (
    <div className={styles.container}>
      <div className="u-container-width">
        <Button
          iconLeft="ACCESSIBILITY"
          variant="primary"
          icon="NAV_ARROW_RIGHT"
          fullWidth
          label={adjustA11y}
          className={styles.button}
          aria-controls={A11Y_PANEL_ID}
          aria-expanded={showAccessibilityPanel ? 'true' : 'false'}
          onClick={() => {
            setStore(s => ({
              ...s,
              showAccessibilityPanel: !s.showAccessibilityPanel,
            }));
          }}
        />
      </div>
    </div>
  );
}
