import { Image as ImageType, ImageAlignment } from '@type-declarations/media';
import Image from 'next/legacy/image';
import { useEffect, useState } from 'react';

import styles from './HeroVideo.module.scss';

interface Props {
  mobile: string;
  desktop: string;
  image: ImageType;
  alignment?: ImageAlignment;
  className?: string;
}

const MINIMUM_DOWNLINK = 1;

export default function HeroVideo({
  mobile,
  desktop,
  image,
  alignment,
  className,
}: Props) {
  const [loadVideo, setLoadVideo] = useState(false);
  const [poster, setPoster] = useState(image.url);

  useEffect(() => {
    const handleVideoLoad = () => {
      let downlink = 1;

      try {
        // @ts-expect-error connection is not always available on navigator. That's why we use the connection
        downlink = navigator.connection.downlink;
      } catch {
        // eslint-disable-next-line no-console
        console.log('Unable to determine downlink');
      }

      if (downlink < MINIMUM_DOWNLINK) {
        setPoster(image.url);
        return;
      }

      setLoadVideo(true);
    };

    if (document.readyState === 'complete') {
      handleVideoLoad();
    } else {
      window.addEventListener('load', handleVideoLoad);
    }

    return () => {
      window.removeEventListener('load', handleVideoLoad);
    };
  }, [image.url]);

  return (
    <div className={className}>
      <Image
        src={poster}
        alt=""
        layout="fill"
        objectFit="cover"
        priority
        objectPosition={alignment?.focusPoint || 'center center'}
      />

      {loadVideo && (
        <video autoPlay loop muted playsInline className={styles.video}>
          <source src={mobile} type="video/mp4" media="(max-width: 768px)" />
          <source src={desktop} type="video/mp4" media="(min-width: 769px)" />
        </video>
      )}
    </div>
  );
}
