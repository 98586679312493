import gql from 'graphql-tag';

import {
  contentItem,
  eventItem,
  landingItem,
  locationItem,
  newsItem,
  routeItem,
} from '../overview-fragment';
import { segmentationFields } from '../segmentation-fields-fragment';

const segmentationSectionFragment = gql`
  fragment segmentationSectionFields on SegmentationSection {
    segmentationFields: segmentation_fields {
      ${segmentationFields}
    }
  }
  ${locationItem}
  ${eventItem}
  ${contentItem}
  ${routeItem}
  ${newsItem}
  ${landingItem}
`;

export default segmentationSectionFragment;
