import gql from 'graphql-tag';

const accessibilitySectionFragment = gql`
  fragment accessibilitySectionFields on AccessibilitySection {
    text
    title
    guideDog: guide_dog
    hearingImpaired: hearing_impaired
    general
    visuallyImpaired: visually_impaired
    walkingDifficulties: walking_difficulties
    backgroundColor: background_color {
      color
    }
  }
`;

export default accessibilitySectionFragment;
