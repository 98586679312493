import WrapInLink from '@atoms/Button/WrapInLink';
import SaveButton from '@atoms/SaveButton/SaveButton';
import { Locale } from '@type-declarations/locale';
import { ZigzagCampaignHighlight } from '@type-declarations/zigzagCampaign';
import datesToLocalizedString from '@utils/datesToLocalizedString';
import getInternalRoute from '@utils/getInternalRoute';
import clsx from 'clsx';

import styles from './HighlightItem.module.scss';

interface HighlightItemProps extends ZigzagCampaignHighlight {
  locale: Locale;
}

export default function HighlightItem({
  typename,
  id,
  slug,
  title,
  dates,
  frequency,
  locale,
}: HighlightItemProps) {
  return (
    <article className={styles.item}>
      <div className={styles.contentContainer}>
        <div className={styles.date}>
          {dates?.length &&
            datesToLocalizedString({
              dates,
              locale,
              frequency,
              showDatesInPast: true,
            })}
        </div>
        <WrapInLink
          href={getInternalRoute({ internalLink: { typename, slug }, locale })}
          className={clsx(styles.link, 'u-umbrella-link')}
        >
          <h1 className={clsx(styles.title, 'u-mb-0')}>{title}</h1>
        </WrapInLink>
      </div>
      <SaveButton
        id={id}
        pageTitle={title}
        pageType={typename}
        className={styles.save}
        variant="iconOnly"
      />
    </article>
  );
}
