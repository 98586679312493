import Icon, { IconType } from '@atoms/Icon/Icon';
import clsx from 'clsx';

interface Props {
  page?: number | undefined;
  label?: string;
  hideLabel?: boolean;
  onClick: (page?: number) => void;
  disabled?: boolean;
  IconName?: IconType;
  className?: string;
  labelClasses?: string;
  iconClasses?: string;
}

function PageButton({
  page,
  label,
  hideLabel = false,
  onClick,
  disabled,
  IconName,
  className,
  labelClasses,
  iconClasses,
}: Props) {
  return (
    <button
      data-cy="page-button"
      type="button"
      className={className}
      onClick={() => onClick(page)}
      disabled={disabled}
    >
      <span
        className={clsx(labelClasses, hideLabel ? 'u-visually-hidden' : '')}
      >
        {label || 'Pagina '}
      </span>
      {page}
      {IconName && <Icon icon={IconName} className={iconClasses} />}
    </button>
  );
}

export default PageButton;
