import { fetchPrepr } from '@lib/prepr/prepr';
import NEWS_PAGE_QUERY from '@lib/queries/newsPage';
import NEWS_PAGES_QUERY from '@lib/queries/newsPages';
import { News } from '@type-declarations/overviewItems';
import { NewsPage } from '@type-declarations/page';
import { QueryParameters } from '@type-declarations/prepr';

/*
  News page
*/
export interface NewsPagesResponse {
  data: {
    NewsPages: {
      items: News[];
      total: number;
    };
  };
}

export interface NewsPageResponse {
  data: {
    NewsPage: NewsPage;
  };
}

export async function getNewsPage({ slug, locale, preview }: QueryParameters) {
  const res = await fetchPrepr<NewsPageResponse>({
    query: NEWS_PAGE_QUERY,
    variables: { slug, locale, preview },
  });
  return res?.data?.NewsPage;
}

/* Event pages */
export async function getNewsPages({
  page,
  locale,
  preview,
  title,
  tags,
  categories,
}: QueryParameters) {
  const res = await fetchPrepr<NewsPagesResponse>({
    query: NEWS_PAGES_QUERY({
      filterCategories: !!categories?.length,
      filterTags: !!tags?.length,
      filterTitle: !!title,
    }),
    variables: { page, locale, preview, title, tags, categories },
  });

  return {
    items: res?.data?.NewsPages.items || [],
    total: res?.data?.NewsPages.total || 0,
  };
}
