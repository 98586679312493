import Sections from '@organisms/Sections/Sections';
import ZigzagHero from '@organisms/ZigzagHero/ZigzagHero';
import { ZigzagCampaignPage } from '@type-declarations/page';

function ZigzagCampaignTemplate({ page }: { page: ZigzagCampaignPage }) {
  const { monthHighlights, sections } = page;

  return (
    <>
      <div data-gsap-zigzag-hero-trigger>
        <ZigzagHero monthHighlights={monthHighlights} />
        <Sections sections={[sections[0]]} />
      </div>

      <Sections sections={[...sections.slice(1)]} />
    </>
  );
}

export default ZigzagCampaignTemplate;
