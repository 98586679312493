import { useOverview } from '@hooks/useOverview';
import useTranslations from '@hooks/useTranslations';
import { getSearchResults } from '@lib/prepr/search';
import Filters from '@molecules/Filters/Filters';
import CardGrid from '@organisms/CardGrid/CardGrid';
import Hero from '@organisms/Hero/Hero';
import SearchForm from '@organisms/SearchForm/SearchForm';
import Sections from '@organisms/Sections/Sections';
import { SearchPage } from '@type-declarations/page';
import { useRef } from 'react';

export default function SearchTemplate({ page }: { page: SearchPage }) {
  const scrollToRef = useRef<HTMLDivElement | null>(null);

  const { sections, items: initItems, total: initTotal, id } = page;

  const {
    items,
    total,
    activeFilters,
    clearFilters,
    handleChange,
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
    loading,
  } = useOverview({
    initItems,
    initTotal,
    scrollToRef,
    fetch: getSearchResults,
  });

  const t = useTranslations();

  return (
    <>
      <Hero
        pageId={id}
        title={
          activeFilters.query
            ? `${total} ${total === 1 ? t.searchResult : t.searchResults}`
            : t.emptySearchQuery
        }
        variant="small"
        colorVariant="gray"
      >
        <SearchForm
          onSubmit={value => handleChange({ type: 'query', value })}
          initialQuery={activeFilters.query}
        />
      </Hero>

      <Filters
        modifier="searchFilters"
        primary={[
          {
            title: t.contentType,
            key: 'contentTypes',
            filters: [
              { value: 'content', title: t.content },
              { value: 'news', title: t.blog },
              { value: 'location', title: t.locations },
              { value: 'event', title: t.events },
              { value: 'route', title: t.routes },
            ],
          },
        ]}
        secondary={[]}
        activeFilters={activeFilters}
        onChange={handleChange}
        onClear={clearFilters}
      />

      <CardGrid
        showType
        loading={loading}
        total={total}
        notFoundTitle={t.noSearchResultsTitle}
        notFoundText={t.noSearchResultsText}
        items={items}
        scrollToRef={scrollToRef}
        currentPage={currentPage}
        totalPages={totalPages}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleGoToPage={handleGoToPage}
      />

      <Sections sections={sections} />
    </>
  );
}
