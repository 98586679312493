import gql from 'graphql-tag';

const faqSectionFragment = gql`
  fragment faqSectionFields on FaqSection {
    title
    text
    faqList: faq_list {
      typename: __typename
      id: _id
      title
      text
    }
    firstQuestionOpen: first_question_open
    backgroundColor: background_color {
      color
    }
  }
`;

export default faqSectionFragment;
