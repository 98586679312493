import Icon from '@atoms/Icon/Icon';
import { routeChangeStart } from '@atoms/NProgress/NProgress';
import useBreadcrumbs from '@hooks/useBreadcrumbs';
import Link from 'next/link';

import styles from './Breadcrumbs.module.scss';

export default function Breadcrumbs() {
  const previousRoute = useBreadcrumbs();
  if (!previousRoute) return null;

  return (
    <nav className={styles.breadcrumbs}>
      <Link
        onClick={routeChangeStart}
        href={previousRoute.route}
        className={styles.breadcrumb}
      >
        <Icon icon="NAV_ARROW_LEFT" className={styles.icon} />
        {previousRoute.label}
      </Link>
    </nav>
  );
}
