import Button from '@atoms/Button/Button';
import { IconType } from '@atoms/Icon/Icon';
import { LANDING_PAGE_THEMES } from '@constants/constants';
import { FilterCollection, FilterEvent } from '@hooks/useFilters';

import styles from './FilterButton.module.scss';

interface Props {
  iconLeft?: IconType;
  filter: { value: string; title: string };
  group?: { key: keyof FilterCollection };
  active: boolean;
  onChange: (event: FilterEvent) => void;
  theme?: 'white' | (typeof LANDING_PAGE_THEMES)[number];
}

function FilterButton({
  iconLeft,
  filter,
  group,
  active,
  onChange,
  theme,
}: Props) {
  return (
    <Button
      iconLeft={iconLeft}
      key={`primary-filter-${filter.value}`}
      label={filter.title}
      variant={theme || 'white'}
      className={styles.button}
      onClick={() =>
        onChange({
          type: group?.key as
            | 'tags'
            | 'accessibility'
            | 'contentTypes'
            | 'title'
            | 'date'
            | 'distance',
          value: filter.value,
        })
      }
      data-name={group?.key}
      data-value={filter.value}
      modifier={active ? 'filterActive' : null}
      icon={active ? 'CHECK' : null}
      noAnimation
    />
  );
}

export default FilterButton;
