import { Locale } from '@type-declarations/prepr';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import PageButton from './PageButton';
import styles from './Pagination.module.scss';

export interface Props {
  currentPage: number;
  totalPages?: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  handleGoToPage: (page?: number) => void;
  loading?: boolean;
}

const translations = {
  previous: {
    nl: 'Vorige',
    en: 'Previous',
  },
  next: {
    nl: 'Volgende',
    en: 'Next',
  },
};

function Pagination({
  currentPage,
  totalPages = 0,
  hasPrevPage,
  hasNextPage,
  handlePrevPage,
  handleNextPage,
  handleGoToPage,
  loading = false,
}: Props) {
  const locale = useRouter().locale as Locale;

  if ((!hasNextPage && !hasPrevPage && !totalPages) || totalPages === 1)
    return null;

  return (
    <div className={styles.pagination}>
      <PageButton
        label={translations.previous[locale]}
        onClick={handlePrevPage}
        IconName="NAV_ARROW_LEFT"
        className={styles.prevPage}
        labelClasses={styles.label}
        iconClasses={styles.icon}
        disabled={loading || currentPage === 1}
      />

      {/* First page */}
      {currentPage > 1 && (
        <PageButton
          hideLabel
          page={1}
          onClick={handleGoToPage}
          className={clsx(
            styles.quickLink,
            currentPage !== totalPages && styles.firstQuickLink
          )}
          disabled={loading}
        />
      )}

      {/* ... */}
      {currentPage - 2 > 1 && (
        <span
          className={clsx(
            currentPage !== totalPages && styles.desktopQuickLink
          )}
        >
          ...
        </span>
      )}

      {/* Previous Mobile */}
      {currentPage - 1 === totalPages - 2 && currentPage - 1 !== 0 && (
        <PageButton
          hideLabel
          page={currentPage - 1}
          onClick={handleGoToPage}
          className={clsx(styles.quickLink, styles.prevNextQuickLinkMobile)}
          disabled={loading}
        />
      )}

      {/* Previous Desktop */}
      {currentPage - 1 > 1 && (
        <PageButton
          hideLabel
          page={currentPage - 1}
          onClick={handleGoToPage}
          className={clsx(styles.quickLink, styles.prevNextQuickLink)}
          disabled={loading}
        />
      )}

      {/* Current */}
      <div data-cy="current-page" className={styles.current}>
        <span className="u-visually-hidden">Pagina </span>
        {currentPage}
      </div>

      {/* Next Mobile */}
      {currentPage === totalPages - 2 && (
        <PageButton
          hideLabel
          page={currentPage + 1}
          onClick={handleGoToPage}
          className={clsx(styles.quickLink, styles.prevNextQuickLinkMobile)}
          disabled={loading}
        />
      )}

      {/* Next desktop */}
      {currentPage + 1 < totalPages && (
        <PageButton
          hideLabel
          page={currentPage + 1}
          onClick={handleGoToPage}
          className={clsx(styles.quickLink, styles.prevNextQuickLink)}
          disabled={loading}
        />
      )}

      {/* ... */}
      {currentPage + 2 < totalPages && <span>...</span>}

      {/* Last page */}
      {currentPage < totalPages && (
        <PageButton
          hideLabel
          page={totalPages}
          onClick={handleGoToPage}
          className={styles.quickLink}
          disabled={loading}
        />
      )}

      <PageButton
        label={translations.next[locale]}
        onClick={handleNextPage}
        IconName="NAV_ARROW_RIGHT"
        className={styles.nextPage}
        labelClasses={styles.label}
        disabled={loading || currentPage === totalPages}
      />
    </div>
  );
}

export default Pagination;
