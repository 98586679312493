import assetFragment from '@lib/fragments/asset-fragment';
import ctaFragment from '@lib/fragments/cta-fragment';
import gql from 'graphql-tag';

const videoSectionFragment = gql`
  fragment videoSectionFields on VideoSection {
    title
    subtitle
    text
    cta {
      ...ctaFields
    }
    video {
      youtubeId: youtube_id
      embeddedVideo: embedded_video {
        playbackId: playback_id
      }
      alt: alt_text
      thumbnail {
        ...assetFields
        url(width: 1280, height: 720, format: "webp")
      }
    }
    backgroundColor: background_color {
      color
    }
    fullWidth: full_width
  }
  ${assetFragment}
  ${ctaFragment}
`;

export default videoSectionFragment;
