import gql from 'graphql-tag';

const iframeSectionFragment = gql`
  fragment iframeSectionFields on IframeSection {
    title
    text
    iframe
    backgroundColor: background_color {
      color
    }
  }
`;

export default iframeSectionFragment;
