import gql from 'graphql-tag';

const eventRegistrationSectionFragment = gql`
  fragment eventRegistrationFields on EventRegistrationSection {
    title
    subtitle
    text
    backgroundColor: background_color {
      color
    }
  }
`;

export default eventRegistrationSectionFragment;
