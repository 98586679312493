import { eventItem } from '@lib/fragments/overview-fragment';
import gql from 'graphql-tag';

interface Props {
  filterCategories?: boolean;
  filterTags?: boolean;
  filterLocations?: boolean;
  filterTitle?: boolean;
}

const EVENT_PAGES_QUERY = ({
  filterCategories,
  filterTags,
  filterLocations,
  filterTitle,
}: Props) => gql`
  query EVENT_PAGES_QUERY(
    ${filterTitle ? '$title: String' : ''}
    ${filterCategories ? '$categories: [String!]' : ''}
    ${filterTags ? '$tags: [String!]' : ''}
    ${filterLocations ? '$locations: [String]' : ''}
    $limit: Int
    $skip: Int
    $locale: String
    $fromGte: _DateTime
    $fromLte: _DateTime
    $untilLte: _DateTime
    $idNany: [String]
  ) {
    EventPages(
      limit: $limit
      skip: $skip
      locale: $locale
      where: {
        _id_nany: $idNany
        dates: { from_gte: $fromGte, from_lte: $fromLte, until_lte: $untilLte, }
        ${filterLocations ? 'location: { _slug_any: $locations }' : ''}
        ${filterCategories ? 'category: { _slug_any: $categories }' : ''}
        ${filterTags ? 'content_tags: { _slug_any: $tags }' : ''}
        _or: [
          ${
            filterTitle
              ? `
            { title_contains: $title }
            { content_tags: { title_contains: $title } }
          `
              : ''
          }
        ]
      }
      sort: dates_ASC
    ) {
      items {
        ...eventItem
      }
      total
    }
  }
  ${eventItem}
`;

export default EVENT_PAGES_QUERY;
