import assetFragment from '@lib/fragments/asset-fragment';
import { newsItem } from '@lib/fragments/overview-fragment';
import gql from 'graphql-tag';

interface Props {
  filterCategories?: boolean;
  filterTags?: boolean;
  filterTitle?: boolean;
}

const NEWS_PAGES_QUERY = ({
  filterCategories,
  filterTags,
  filterTitle,
}: Props) => gql`
  query NEWS_PAGES_QUERY(
    ${filterTitle ? '$title: String' : ''}
    ${filterCategories ? '$categories: [String!]' : ''}
    ${filterTags ? '$tags: [String!]' : ''}
    $limit: Int
    $skip: Int
    $locale: String
  ) {
    NewsPages(
      where: {
        ${filterCategories ? 'category: { _slug_any: $categories }' : ''}
        ${filterTags ? 'content_tags: { _slug_any: $tags }' : ''}
        _or: [
          ${
            filterTitle
              ? `
            { title_contains: $title }
            { content_tags: { title_contains: $title } }
          `
              : ''
          }
        ]
      }
      limit: $limit
      skip: $skip
      locale: $locale
    ) {
      items {
        ...newsItem
      }
      total
    }
  }
  ${assetFragment}
  ${newsItem}
`;

export default NEWS_PAGES_QUERY;
