import { accessibilityFields } from '@lib/fragments/accessibility';
import { addressFields } from '@lib/fragments/address-fragment';
import assetFragment from '@lib/fragments/asset-fragment';
import ctaFragment from '@lib/fragments/cta-fragment';
import { localizationsFields } from '@lib/fragments/localizations-fragment';
import {
  sectionsFields,
  sectionsFragment,
} from '@lib/fragments/sections-fragment';
import { seoFields } from '@lib/fragments/seo-fragment';
import { tagFragment } from '@lib/fragments/tag-fragment';
import gql from 'graphql-tag';

const EVENT_PAGE_QUERY = gql`
  query EVENT_PAGE_QUERY($id: String, $slug: String, $locale: String) {
    EventPage(id: $id, slug: $slug, locale: $locale) {
      typename: __typename
      id: _id
      slug: _slug
      locales: _locales
      title
      introText: intro_text
      tags: content_tags {
        ${tagFragment}
      }
      mail
      phone
      website
      location {
        # image used for richdata
        image {
          ...assetFields
          url(width: 600, format: "webp")
        }
        coordinates {
          latitude
          longitude
        }
        address {
          ${addressFields}
        }
      }
      address {
        ${addressFields}
      }
      coordinates {
        latitude
        longitude
      }
      dates {
        from
        until
      }
      image {
        ...assetFields
        url(width: 1920, format: "webp")
      }
      alignment { 
        focusPoint: focus_point
      }
      heroColor: hero_color {
        color
      }
      youtubeId: youtube_id
      extraImages: extra_images {
        ...assetFields
        url(width: 800, height: 500, format: "webp")
      }
      # SubSub
      category {
        heroColor: hero_color {
          color
        }
        # Sub
        category {
          colorPalette: color_palette
          heroColor: hero_color {
            color
          }
          # Main
          category {
            colorPalette: color_palette
            heroColor: hero_color {
              color
            }
          }
        }
      }
      description
      cta {
        ...ctaFields
      }
      price
      ${accessibilityFields}
      ${localizationsFields}
      ${sectionsFields}
      ${seoFields}
    }
  }
  ${assetFragment}
  ${sectionsFragment}
  ${ctaFragment}
`;

export default EVENT_PAGE_QUERY;
