import useScrollTrigger from '@hooks/useScrollTrigger';
import useStore from '@hooks/useStore';
import CtaButton from '@molecules/CtaButton/CtaButton';
import LogoLink from '@organisms/Header/LogoLink';
import ZigzagLogoNLIcon from '@svg/zigzag-logo-nl.svg';
import { ZigzagCampaignMonth } from '@type-declarations/zigzagCampaign';
import clsx from 'clsx';
import { useRef } from 'react';

import HighlightItem from './HighlightItem';
import styles from './ZigzagHero.module.scss';
import animation from './ZigzagHeroAnimation';

interface ZigzagHeroProps {
  monthHighlights: ZigzagCampaignMonth[];
}

export default function ZigzagHero({ monthHighlights }: ZigzagHeroProps) {
  const {
    store: { locale },
  } = useStore();
  const ref = useRef<HTMLDivElement>(null!);

  useScrollTrigger({ ref, animation });

  return (
    <div className={styles.hero}>
      <div className={styles.contentContainer}>
        <div className={styles.wrapper}>
          <div className={styles.logoWrapper}>
            <LogoLink zigzagCampaignHero />
          </div>

          <ZigzagLogoNLIcon className={styles.logo} />
        </div>
      </div>
      <div className={styles.monthsContainer} ref={ref}>
        <div className={styles.monthsWrapper}>
          {monthHighlights.map(month => (
            <div
              key={month.id}
              className={styles.monthContainer}
              data-gsap-zigzag-month
            >
              <h2 className={styles.monthTitle}>{month.title}</h2>
              <div className={styles.monthContent}>
                <h3 className={styles.monthLabel}>{month.month}</h3>
                <ul className={clsx(styles.highlights, 'u-list-clean u-mb-0')}>
                  {month.highlights.map(item => (
                    <li key={item.id}>
                      <HighlightItem
                        typename={item.typename}
                        id={item.id}
                        title={item.title}
                        dates={item.dates}
                        frequency={item.frequency}
                        slug={item.slug}
                        locale={locale}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {month.cta && (
                <div className={styles.linkWrapper}>
                  <CtaButton
                    cta={month.cta}
                    variant="text"
                    className={styles.link}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
