import ctaFragment from '@lib/fragments/cta-fragment';
import gql from 'graphql-tag';

const ctaButtonsSectionFragment = gql`
  fragment ctaButtonsSectionFields on CtaButtonsSection {
    title
    text
    ctaButtons: cta_buttons {
      ctaButtons: cta_buttons {
        ...ctaFields
      }
    }
    backgroundColor: background_color {
      color
    }
  }
  ${ctaFragment}
`;

export default ctaButtonsSectionFragment;
