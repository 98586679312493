import gql from 'graphql-tag';

import {
  contentItem,
  eventItem,
  locationItem,
  newsItem,
  routeItem,
} from '../overview-fragment';

const contentCarouselSectionFragment = gql`
  fragment contentCarouselSectionFields on ContentCarouselSection {
    title
    subtitle
    text
    imageSize: image_size
    cardSize: card_size
    backgroundColor: background_color {
      color
    }

    # Carousel queries:
    contentTypeContent: content_type_content
    contentTypeEvent: content_type_event
    contentTypeLocation: content_type_location
    contentTypeNews: content_type_news
    contentTypeRoute: content_type_route
    filterCategory: filter_category {
      typename: __typename
      ... on Model {
        slug: _slug
      }

      ... on SubCategory {
        slug: _slug

        category {
          slug: _slug
        }
      }

      ... on SubSubCategory {
        slug: _slug

        category {
          slug: _slug

          category {
            slug: _slug
          }
        }
      }
    }
    filterTag: filter_tag {
      slug: _slug
    }
    maxCount: max_count
    selection {
      ...locationItem
      ...eventItem
      ...contentItem
      ...routeItem
      ...newsItem
    }
  }
  ${locationItem}
  ${eventItem}
  ${contentItem}
  ${routeItem}
  ${newsItem}
`;

export default contentCarouselSectionFragment;
