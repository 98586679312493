import assetFragment from '@lib/fragments/asset-fragment';
import { locationItem } from '@lib/fragments/overview-fragment';
import gql from 'graphql-tag';

interface Props {
  filterRadius?: boolean;
  filterCategories?: boolean;
  filterTags?: boolean;
  filterTitle?: boolean;
}

export const LOCATION_PAGES_TOTAL_QUERY = ({ filterRadius }: Props) => gql`
  query LOCATION_PAGES_TOTAL_QUERY(
    ${
      filterRadius
        ? `
      $lat: Float
      $lon: Float
      $radius: Int
    `
        : ''
    }
  ) {
    LocationPages(
      where: {
        ${
          filterRadius
            ? `coordinates_within_circle: { lat: $lat, lon: $lon, radius: $radius }`
            : ''
        }
      }
    ) {
      total
    }
  }
`;

const LOCATION_PAGES_QUERY = ({
  filterRadius,
  filterCategories,
  filterTags,
  filterTitle,
}: Props) => gql`
  query LOCATION_PAGES_QUERY(
    ${filterTitle ? '$title: String' : ''}
    ${filterCategories ? '$categories: [String!]' : ''}
    ${filterTags ? '$tags: [String!]' : ''}
    $a11yGeneral: Boolean
    $a11yGuideDog: Boolean
    $a11yHearingImpaired: Boolean
    $a11yVisuallyImpaired: Boolean
    $a11yWalkingDifficulties: Boolean
    $limit: Int
    $skip: Int
    $locale: String
    ${
      filterRadius
        ? `
      $lat: Float
      $lon: Float
      $radius: Int
    `
        : ''
    }
  ) {
    LocationPages(
      where: {
        ${filterCategories ? 'category: { _slug_any: $categories }' : ''}
        ${filterTags ? 'content_tags: { _slug_any: $tags }' : ''}
        a11y_general: $a11yGeneral
        a11y_guide_dog: $a11yGuideDog
        a11y_hearing_impaired: $a11yHearingImpaired
        a11y_visually_impaired: $a11yVisuallyImpaired
        a11y_walking_difficulties: $a11yWalkingDifficulties
        ${
          filterRadius
            ? `coordinates_within_circle: { lat: $lat, lon: $lon, radius: $radius }`
            : ''
        }
        _or: [
          ${
            filterTitle
              ? `
            { title_contains: $title }
            { content_tags: { title_contains: $title } }
          `
              : ''
          }
        ]
      }
      limit: $limit
      skip: $skip
      locale: $locale
    ) {
      items {
        ...locationItem
      }
      total
    }
  }
  ${assetFragment}
  ${locationItem}
`;

export default LOCATION_PAGES_QUERY;
