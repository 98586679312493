import Button from '@atoms/Button/Button';
import Icon from '@atoms/Icon/Icon';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import { StoreModifier } from '@type-declarations/modifier';
import { RouteType } from '@type-declarations/prepr';
import { formatDistance } from '@utils/formatDistance';
import formatDuration from '@utils/formatDuration';
import clsx from 'clsx';

import styles from './RouteTopBar.module.scss';

function RouteDistance({
  distance,
  duration,
}: {
  distance?: number;
  duration?: number;
}) {
  const {
    store: { locale },
  } = useStore();

  if (distance && duration) {
    return (
      <>
        {formatDistance(distance)}{' '}
        <span className={styles.duration}>
          ({formatDuration(duration, locale)})
        </span>
      </>
    );
  }

  if (distance) {
    return formatDistance(distance);
  }

  if (duration) {
    return formatDuration(duration, locale);
  }
}

interface Props {
  gpx?: {
    url: string;
  };
  distance?: number;
  duration?: number;
  stopsCount?: number;
  routeType: RouteType;
  modifier?: StoreModifier;
}

export default function RouteTopBar({
  gpx,
  distance,
  duration,
  routeType,
  stopsCount = 0,
  modifier,
}: Props) {
  const { locations, downloadRouteGpx } = useTranslations();

  if (!gpx?.url && !distance && !duration) return null;

  return (
    <div className={clsx(styles.container, modifier && styles[modifier])}>
      <div className="u-container">
        <ul className={clsx(styles.list, 'u-list-clean')}>
          {(distance || duration) && (
            <li className={styles.item}>
              <Icon icon={routeType || 'WALKING'} className={styles.icon} />
              <RouteDistance distance={distance} duration={duration} />
            </li>
          )}
          {stopsCount > 0 && (
            <li className={styles.item}>
              <Icon icon="MAP_PIN" className={styles.icon} />
              {stopsCount} {locations}
            </li>
          )}
          {gpx?.url && (
            <li>
              <Button
                modifier="redIcon"
                iconLeft="DOWNLOAD"
                label={downloadRouteGpx}
                href={gpx.url}
                variant="text"
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
