import { fetchPrepr } from '@lib/prepr/prepr';
import SEARCH_QUERY from '@lib/queries/search';
import { QueryParameters } from '@type-declarations/prepr';
import { prepareContentTypeForQuery } from '@utils/filters/contentTypeFilter';

import { SearchResponse } from './dynamic';

export async function getSearchResults({
  query,
  contentTypes,
  page,
  locale,
  preview,
}: QueryParameters) {
  if (!query) {
    return { items: [], total: 0 };
  }

  const res = await fetchPrepr<SearchResponse>({
    query: SEARCH_QUERY,
    variables: {
      page,
      query,
      locale,
      preview,
      contentTypes: prepareContentTypeForQuery(contentTypes),
    },
  });

  return {
    items: res?.data?.ContentItems.items || [],
    total: res?.data?.ContentItems.total || 0,
  };
}
