import { fetchPrepr } from '@lib/prepr/prepr';
import STORE_EXPLORE_GROUPS_QUERY from '@lib/queries/store/exploreGroups';
import { Locale } from '@type-declarations/locale';
import { ExploreGroupsResponse } from '@type-declarations/store/group';

export async function getStoreGroupItems({
  locale,
  slugs,
}: {
  locale: Locale;
  slugs: string[];
}) {
  const res = await fetchPrepr<ExploreGroupsResponse>({
    query: STORE_EXPLORE_GROUPS_QUERY({ withContent: true }),
    variables: { locale, slugs },
  });

  return (
    res?.data?.StoreExploreGroups.items
      .flatMap(group => group.items || [])
      // Remove duplicates
      .filter(
        (item, i, self) => self.findIndex(({ id }) => id === item.id) === i
      ) || []
  );
}
