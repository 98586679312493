import { ITEMS_PER_PAGE } from '@constants/constants';
import { useRouter } from 'next/router';
import { MutableRefObject, useEffect, useState } from 'react';

const SCROLL_OFFSET = 64;

interface Props {
  total: number;
  limit?: number;
  fetchMore: (page: number) => void;
  scrollToRef: MutableRefObject<HTMLDivElement | null>;
}

export default function usePagination({
  total,
  limit = ITEMS_PER_PAGE,
  fetchMore,
  scrollToRef,
}: Props) {
  const { query, ...router } = useRouter();
  const [currentPage, setCurrentPage] = useState(Number(query.page) || 1);
  const totalPages = Math.ceil(total / limit);
  const hasPrevPage = currentPage > 1;
  const hasNextPage = !!totalPages && totalPages !== currentPage;

  const scrollTo = () => {
    window.scrollTo({
      top: (scrollToRef?.current?.offsetTop || 0) - SCROLL_OFFSET,
      behavior: 'smooth',
    });
  };

  const handleRoute = (page?: number) => {
    router.push(
      {
        href: router.pathname,
        query: { ...query, page },
      },
      undefined,
      { shallow: true, scroll: false }
    );
  };

  const handlePrevPage = () => {
    const updatedCurrentPage = currentPage - 1;
    setCurrentPage(updatedCurrentPage);
    fetchMore(updatedCurrentPage);
    handleRoute(updatedCurrentPage);
    scrollTo();
  };

  const handleNextPage = () => {
    const updatedCurrentPage = currentPage + 1;
    setCurrentPage(updatedCurrentPage);
    fetchMore(updatedCurrentPage);
    handleRoute(updatedCurrentPage);
    scrollTo();
  };

  // Navigate directly to a specific page
  const handleGoToPage = (page?: number) => {
    if (!page) return null;

    // Check if the page exists in the results
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      handleRoute(page);
      fetchMore(page);
      return scrollTo();
    }
    return null;
  };

  // Reset internal pagination
  const resetPagination = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    if (currentPage !== Number(query.page)) {
      resetPagination();
    }
    // eslint-disable-next-line
  }, [query.page]);

  return {
    currentPage,
    totalPages,
    hasPrevPage,
    hasNextPage,
    handlePrevPage,
    handleNextPage,
    handleGoToPage,
    resetPagination,
  };
}
