import Icon from '@atoms/Icon/Icon';
import useTranslations from '@hooks/useTranslations';
import clsx from 'clsx';

import styles from './FilterBar.module.scss';

type Filter = 'all' | 'bookmarked';

interface Props {
  active: Filter;
  onChange: (active: Filter) => void;
}

export default function FilterBar({ active, onChange }: Props) {
  const t = useTranslations();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <button
          type="button"
          className={clsx(styles.filter, active === 'all' && styles.active)}
          onClick={() => onChange('all')}
        >
          <Icon icon="MAP" className={styles.icon} />
          {t.allLocations}
        </button>
        <button
          type="button"
          className={clsx(
            styles.filter,
            active === 'bookmarked' && styles.active
          )}
          onClick={() => onChange('bookmarked')}
        >
          <Icon icon="BOOKMARK" className={styles.icon} />
          {t.myLocations}
        </button>
      </div>
    </div>
  );
}
