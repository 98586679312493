import { Locale } from '@type-declarations/locale';
import { QueryParameters } from '@type-declarations/prepr';
import { useRouter } from 'next/router';
import { RefObject, useState } from 'react';

import useFilters, { FilterCollection } from './useFilters';
import usePagination from './usePagination';

interface Props<T> {
  initItems: T[];
  initTotal: number;
  scrollToRef: RefObject<HTMLDivElement | null>;
  fetch: (data: QueryParameters) => Promise<{ items: T[]; total: number }>;
}

export function useOverview<T>({
  initItems,
  initTotal = 0,
  scrollToRef,
  fetch,
}: Props<T>) {
  const router = useRouter();
  const locale = router.locale as Locale;
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    items: initItems,
    total: initTotal,
  });

  const handleFetch = async ({
    page = 1,
    filterCollection,
  }: {
    page?: number;
    filterCollection: FilterCollection;
  }) => {
    setLoading(true);

    const { items, total } = await fetch({
      ...filterCollection,
      page,
      locale,
      preview: false,
    });

    setLoading(false);

    setData({
      items,
      total,
    });
  };

  const { filters, handleChange, clearFilters } = useFilters({
    callback: filterCollection => handleFetch({ filterCollection }),
  });

  const {
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
  } = usePagination({
    total: data.total,
    fetchMore: page => handleFetch({ page, filterCollection: filters }),
    scrollToRef,
  });

  return {
    ...data,
    activeFilters: filters,
    clearFilters,
    handleChange,
    loading,
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
  };
}
