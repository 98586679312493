import { fetchPrepr } from '@lib/prepr/prepr';
import LOCATION_PAGE_QUERY from '@lib/queries/locationPage';
import LOCATION_PAGES_QUERY from '@lib/queries/locationPages';
import { Location } from '@type-declarations/overviewItems';
import { LocationPage } from '@type-declarations/page';
import { QueryParameters } from '@type-declarations/prepr';

export interface LocationPagesResponse {
  data: {
    LocationPages: {
      items: Location[];
      total: number;
    };
  };
}

export interface LocationPageResponse {
  data: {
    LocationPage: LocationPage;
  };
}

export async function getLocationPage({
  slug,
  locale,
  preview,
}: QueryParameters) {
  const res = await fetchPrepr<LocationPageResponse>({
    query: LOCATION_PAGE_QUERY,
    variables: { slug, locale, preview },
  });
  return res?.data?.LocationPage;
}

/* Location pages */
export async function getLocationPages({
  tags,
  categories,
  accessibility,
  title,
  page,
  locale,
  preview,
  limit,
  lat,
  lon,
  radius,
}: QueryParameters) {
  const res = await fetchPrepr<LocationPagesResponse>({
    query: LOCATION_PAGES_QUERY({
      filterCategories: !!categories?.length,
      filterTags: !!tags?.length,
      filterTitle: !!title,
      filterRadius: !!lat && !!lon && !!radius,
    }),
    variables: {
      page,
      tags,
      categories,
      a11yGeneral: accessibility?.includes('general') || null,
      a11yGuideDog: accessibility?.includes('guideDog') || null,
      a11yHearingImpaired: accessibility?.includes('hearingImpaired') || null,
      a11yVisuallyImpaired: accessibility?.includes('visuallyImpaired') || null,
      a11yWalkingDifficulties:
        accessibility?.includes('walkingDifficulties') || null,
      title,
      locale,
      preview,
      limit,
      lat,
      lon,
      radius,
    },
  });

  return {
    items: res?.data?.LocationPages.items || [],
    total: res?.data?.LocationPages.total || 0,
  };
}
