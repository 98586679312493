import useFocusTrap from '@hooks/useFocusTrap';
import CloseButton from '@molecules/CloseButton/CloseButton';
import clsx from 'clsx';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './Modal.module.scss';

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  footer?: JSX.Element;
}

export default function Modal({
  id,
  isOpen,
  onClose,
  title,
  footer,
  children,
}: PropsWithChildren<Props>) {
  const panelRef = useRef<HTMLDivElement | null>(null);
  const panelCloseRef = useRef<HTMLButtonElement | null>(null);
  const { enableFocusTrap, disableFocusTrap } = useFocusTrap(
    panelRef,
    panelCloseRef
  );

  useEffect(() => {
    if (isOpen) {
      enableFocusTrap();
    } else {
      disableFocusTrap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div id={id}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={clsx(styles.backdrop, isOpen && styles.showBackdrop)}
        onClick={onClose}
        onKeyDown={onClose}
      />

      <CSSTransition
        in={isOpen}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: styles.fadeEnter,
          enterActive: styles.fadeEnterActive,
          exit: styles.fadeExit,
          exitActive: styles.fadeExitActive,
        }}
      >
        <div className={styles.modal} ref={panelRef}>
          <header className={clsx(styles.header, 'u-container-width')}>
            <h2 className={styles.title}>{title}</h2>

            <CloseButton
              onClick={onClose}
              controls={{ id, expanded: isOpen }}
            />
          </header>
          <div className={clsx(styles.content, 'u-container-width')}>
            {children}
          </div>
          {footer && (
            <footer className={clsx(styles.footer, 'u-container-width')}>
              {footer}
            </footer>
          )}
        </div>
      </CSSTransition>
    </div>
  );
}
