import ctaFragment from '@lib/fragments/cta-fragment';
import gql from 'graphql-tag';

const textSectionFragment = gql`
  fragment textSectionFields on TextSection {
    title
    subtitle
    text
    cta {
      ...ctaFields
    }
    backgroundColor: background_color {
      color
    }
  }
  ${ctaFragment}
`;

export default textSectionFragment;
