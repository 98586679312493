import gql from 'graphql-tag';

const languageFormSectionFragment = gql`
  fragment languageFormSectionFields on ExploreFormSection {
    title
    ctaLabel: cta_label
    backgroundColor: background_color {
      color
    }
  }
`;

export default languageFormSectionFragment;
