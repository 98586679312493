export const DATE_FILTERS = {
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  WEEKEND: 'weekend',
};

export const DATE_FILTER_PRESETS = [
  DATE_FILTERS.TODAY,
  DATE_FILTERS.TOMORROW,
  DATE_FILTERS.WEEKEND,
];
