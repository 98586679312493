import { DATE_FILTER_PRESETS, DATE_FILTERS } from '@constants/filters';
import { RangeFilter } from '@type-declarations/filter';
import {
  addMinutes,
  endOfToday,
  endOfTomorrow,
  endOfWeek,
  getDate,
  isSaturday,
  isSunday,
  startOfDay,
  startOfToday,
  startOfTomorrow,
  sub,
} from 'date-fns';

export function isActiveDateFilter(date: RangeFilter, comparison: string) {
  if (comparison === DATE_FILTERS.TODAY) {
    return (
      date[0] === startOfToday().getTime() && date[1] === endOfToday().getTime()
    );
  }

  if (comparison === DATE_FILTERS.TOMORROW) {
    return (
      date[0] === startOfTomorrow().getTime() &&
      date[1] === endOfTomorrow().getTime()
    );
  }

  if (comparison === DATE_FILTERS.WEEKEND) {
    const today = startOfToday();

    if (isSunday(today)) {
      return date[0] === today.getTime() && date[1] === endOfToday().getTime();
    }

    if (isSaturday(today)) {
      return (
        date[0] === today.getTime() && date[1] === endOfTomorrow().getTime()
      );
    }

    const sunday = endOfWeek(today, { weekStartsOn: 1 });
    const friday = startOfDay(sub(sunday, { days: 2 }));

    return date[0] === friday.getTime() && date[1] === sunday.getTime();
  }

  return false;
}

export function convertToDateFilter(
  value: string,
  current: RangeFilter | null
): RangeFilter | null {
  if (!DATE_FILTER_PRESETS.includes(value)) return null;

  if (current && isActiveDateFilter(current, value)) {
    return null;
  }

  if (value === DATE_FILTERS.TODAY) {
    return [startOfToday().getTime(), endOfToday().getTime()];
  }

  if (value === DATE_FILTERS.TOMORROW) {
    return [startOfTomorrow().getTime(), endOfTomorrow().getTime()];
  }

  if (value === DATE_FILTERS.WEEKEND) {
    const today = startOfToday();

    if (isSunday(today)) {
      return [today.getTime(), endOfToday().getTime()];
    }

    if (isSaturday(today)) {
      return [today.getTime(), endOfTomorrow().getTime()];
    }

    const sunday = endOfWeek(today, { weekStartsOn: 1 });
    const friday = startOfDay(sub(sunday, { days: 2 }));

    return [friday.getTime(), sunday.getTime()];
  }

  return null;
}

export function normalizeDateFilter(
  date: string | string[] | undefined
): RangeFilter | null {
  if (!date || typeof date === 'string' || date.length !== 2) return null;

  return [+date[0], +date[1]];
}

/**
 * RangeFilter | null | undefined when using client side filtering
 * string | string[] when using client side filtering
 */
export function prepareDateForQuery(
  date: RangeFilter | null | undefined | string | string[]
) {
  if (!date || typeof date === 'string' || date.length !== 2) {
    return { fromGte: startOfToday().toISOString() };
  }

  const isSameDay = getDate(new Date(+date[0])) === getDate(new Date(+date[1]));

  const from: Date = date ? new Date(+date[0]) : startOfToday();
  const until: Date | null = date ? new Date(+date[1]) : null;

  const fromWithOffset = addMinutes(
    from,
    -from.getTimezoneOffset()
  ).toISOString();
  const untilWithOffset = until
    ? addMinutes(until, -until.getTimezoneOffset()).toISOString()
    : null;

  if (isSameDay) {
    return {
      fromGte: fromWithOffset,
      fromLte: untilWithOffset,
    };
  }

  return {
    fromGte: fromWithOffset,
    untilLte: untilWithOffset,
  };
}
