import gql from 'graphql-tag';

import { eventItem } from '../overview-fragment';

const eventCarouselSectionFragment = gql`
  fragment eventCarouselSectionFields on EventCarouselSection {
    title
    subtitle
    text
    backgroundColor: background_color {
      color
    }

    # Carousel queries:
    filterCategory: filter_category {
      typename: __typename
      ... on Model {
        slug: _slug
      }

      ... on SubCategory {
        slug: _slug

        category {
          slug: _slug
        }
      }

      ... on SubSubCategory {
        slug: _slug

        category {
          slug: _slug

          category {
            slug: _slug
          }
        }
      }
    }
    filterTag: filter_tag {
      slug: _slug
    }
    filterLocations: filter_locations {
      slug: _slug
    }
    maxCount: max_count
    selection {
      ...eventItem
    }
  }
  ${eventItem}
`;

export default eventCarouselSectionFragment;
