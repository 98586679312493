import { useOverview } from '@hooks/useOverview';
import useStore from '@hooks/useStore';
import { getNewsPages } from '@lib/prepr/news';
import Filters from '@molecules/Filters/Filters';
import CardGrid from '@organisms/CardGrid/CardGrid';
import Hero from '@organisms/Hero/Hero';
import Sections from '@organisms/Sections/Sections';
import { FilterGroup } from '@type-declarations/filters';
import { NewsOverviewPage } from '@type-declarations/page';
import { groupCategoryFilters } from '@utils/filters/categoryFilter';
import { tagToFilter } from '@utils/filters/tagFilter';
import { useRef } from 'react';

interface Props {
  page: NewsOverviewPage;
}

const translations = {
  nl: {
    nothingFoundTitle: 'Er zijn geen artikelen gevonden',
    nothingFoundText: 'Pas uw filter input aan of probeer het later nog eens.',
    searchLabel: 'Zoek naar artikel',
    tags: 'Kenmerken',
    categories: 'Categorieën',
  },
  en: {
    nothingFoundTitle: 'No articles found',
    nothingFoundText: 'Try changing your filter input or try again later.',
    searchLabel: 'Search for article',
    tags: 'Tags',
    categories: 'Categories',
  },
};

export default function NewsOverviewTemplate({ page }: Props) {
  const {
    store: { locale },
  } = useStore();

  const scrollToRef = useRef<HTMLDivElement | null>(null);

  const {
    title,
    introText,
    image,
    items: initItems,
    total: initTotal,
    alignment,
  } = page;
  const {
    total,
    items,
    activeFilters,
    clearFilters,
    handleChange,
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
    loading,
  } = useOverview({ initItems, initTotal, scrollToRef, fetch: getNewsPages });

  const categoryGroups: FilterGroup[] = groupCategoryFilters(page.categories);

  const primaryTags = tagToFilter(page.primaryTags);

  return (
    <>
      <Hero
        title={title}
        text={introText}
        image={image}
        colorVariant="gray"
        alignment={alignment}
      />

      <Filters
        hideSubSubCategories
        primary={[
          {
            key: 'tags',
            filters: primaryTags,
          },
        ]}
        secondary={[
          ...categoryGroups,
          {
            title: translations[locale].tags,
            key: 'tags',
            filters: [...primaryTags, ...tagToFilter(page.extraTags)],
          },
        ]}
        activeFilters={activeFilters}
        searchLabel={translations[locale].searchLabel}
        onChange={handleChange}
        onClear={clearFilters}
      />

      <CardGrid
        total={total}
        loading={loading}
        notFoundTitle={translations[locale].nothingFoundTitle}
        notFoundText={translations[locale].nothingFoundText}
        items={items}
        scrollToRef={scrollToRef}
        currentPage={currentPage}
        totalPages={totalPages}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleGoToPage={handleGoToPage}
      />

      <Sections sections={page.sections} />
    </>
  );
}
