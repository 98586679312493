// import { ChangeEvent } from 'react';
import Icon from '@atoms/Icon/Icon';
import TextInput from '@atoms/Inputs/TextInput';
import useDebounce from '@hooks/useDebounce';
import useTranslations from '@hooks/useTranslations';
import clsx from 'clsx';
import { InputHTMLAttributes, useState } from 'react';

import styles from './SearchInput.module.scss';

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label: string;
  onChange: (value: string) => void;
  className?: string;
  icon?: boolean;
  modifier?: 'withButton' | null;
  debounceTimerInMs?: number;
}

export default function SearchInput({
  name,
  label,
  defaultValue,
  onChange,
  className,
  icon = false,
  modifier,
  debounceTimerInMs = 300,
}: Props) {
  const { clearSearchQuery: clearSearchQueryTranslation } = useTranslations();
  const [query, setQuery] = useState(defaultValue);

  const handleSearch = useDebounce<string>(value => {
    onChange(value);
  }, debounceTimerInMs);

  const handleChange = (value: string) => {
    setQuery(value);
    handleSearch(value);
  };

  return (
    <TextInput
      hideLabel
      name={name}
      icon={icon ? 'SEARCH' : null}
      iconClassName={styles.icon}
      label={label}
      value={query}
      placeholder={label}
      className={clsx(styles.input, className)}
      onChange={e => handleChange(e.target.value)}
      wrapperClassName={modifier && styles[modifier]}
    >
      {query && (
        <button
          type="button"
          aria-label={clearSearchQueryTranslation}
          className={styles.clear}
          onClick={() => handleChange('')}
          tabIndex={-1}
        >
          <Icon icon="XMARK" className={clsx(styles.icon, styles.clearIcon)} />
        </button>
      )}
    </TextInput>
  );
}
