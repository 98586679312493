import EventSchema from '@atoms/schemas/eventSchema';
import { BG_DEFAULT } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import EventInfo from '@organisms/EventInfo/EventInfo';
import Hero from '@organisms/Hero/Hero';
import HeroCtas from '@organisms/Hero/HeroCtas';
import HighlightedBannerSection from '@organisms/HighlightedBannerSection/HighlightedBannerSection';
import ImageSliderSection from '@organisms/ImageSliderSection/ImageSliderSection';
import Sections from '@organisms/Sections/Sections';
import VideoSection from '@organisms/VideoSection/VideoSection';
import { EventPage as Props } from '@type-declarations/page';
import { getHeroColor } from '@utils/getHeroColor';
import { findHighlightedTag } from '@utils/getHighlightedTag';
import { useMemo } from 'react';

const translations = {
  nl: {
    eventPassed: 'Let op: Dit evenement is verlopen en niet meer actief',
  },
  en: {
    eventPassed: 'Please note: This event has expired and is no longer active',
  },
};

function EventTemplate({ page }: { page: Props }) {
  const {
    store: { locale },
  } = useStore();
  const {
    id,
    title,
    introText,
    image,
    alignment,
    phone,
    mail,
    website,
    dates,
    address,
    coordinates,
    location,
    description,
    price,
    cta,
    a11yGeneral,
    a11yGuideDog,
    a11yHearingImpaired,
    a11yVisuallyImpaired,
    a11yWalkingDifficulties,
    tags,
  } = page;
  const t = useTranslations();

  const heroColor = useMemo(() => getHeroColor({ page }), [page]);

  const sortedDates = useMemo(
    () =>
      dates?.sort((a, b) => {
        if (a.until < b.until) {
          return -1;
        }
        if (a.until > b.until) {
          return 1;
        }

        return 0;
      }) || [],
    [dates]
  );

  const lastDate = !!sortedDates.length && new Date(sortedDates.at(-1)!.until);
  const todayDate = new Date();
  const isEventInPast = lastDate && lastDate.getTime() < todayDate.getTime();
  const highlightedTag = useMemo(() => findHighlightedTag(tags), [tags]);

  return (
    <>
      <Hero
        pageId={id}
        title={title}
        text={introText}
        image={image}
        colorVariant={heroColor}
        containerSize="narrow"
        warning={isEventInPast ? translations[locale].eventPassed : undefined}
        showTopBar
        alignment={alignment}
      >
        <HeroCtas
          location={{
            address: address?.street ? address : location?.[0]?.address,
            coordinates: coordinates || location?.[0]?.coordinates,
          }}
          phone={phone}
          mail={mail}
          website={website}
          dates={sortedDates}
        />
      </Hero>
      <EventInfo
        dates={sortedDates}
        price={price}
        cta={cta}
        text={description}
        general={a11yGeneral}
        guideDog={a11yGuideDog}
        hearingImpaired={a11yHearingImpaired}
        visuallyImpaired={a11yVisuallyImpaired}
        walkingDifficulties={a11yWalkingDifficulties}
      />
      {highlightedTag && (
        <HighlightedBannerSection
          icon={highlightedTag.icon}
          bannerTitle={
            highlightedTag.bannerTitle || t.highlightedBannerSectionTitle
          }
          bannerText={
            highlightedTag.bannerText || t.highlightedBannerSectionText
          }
          theme={highlightedTag.theme}
          bannerCta={highlightedTag.bannerCta}
        />
      )}

      {page.youtubeId && (
        <VideoSection
          id="event-video"
          typename="VideoSection"
          video={{ youtubeId: page.youtubeId }}
          backgroundColor={{ color: BG_DEFAULT }}
          fullWidth={false}
        />
      )}
      {page.extraImages && (
        <ImageSliderSection
          id="event-images"
          typename="ImageSliderSection"
          images={page.extraImages}
          backgroundColor={{ color: BG_DEFAULT }}
        />
      )}
      <Sections sections={page.sections} />
      <EventSchema page={page} />
    </>
  );
}

export default EventTemplate;
