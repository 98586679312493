interface HeroAnimationProps {
  ref: React.RefObject<HTMLDivElement>;
  gsap: typeof gsap;
}

function heroAnimation({ ref, gsap }: HeroAnimationProps) {
  if (!ref?.current) return;
  const mm = gsap.matchMedia();
  const months = ref.current.querySelectorAll<HTMLDivElement>(
    '[data-gsap-zigzag-month]'
  );
  const scrollTrigger = {
    trigger: '[data-gsap-zigzag-hero-trigger]',
    start: 'top top',
    endTrigger: months[months.length - 1],
    end: 'bottom top',
    pin: '[data-gsap-zigzag-hero-trigger]',
    pinSpacing: true,
    scrub: 1,
  };
  // 39 is the height of the top before element
  const topOfInnerContainer = 39;
  const bottomAfterHeight = 45;
  // 45 is the height of the bottom after element
  const bottomOfInnerContainer = ref.current.offsetHeight - bottomAfterHeight;
  // The first month is in view by default so we can skip that
  const monthsToWatch = [months[1], months[2]];

  mm.add('(min-width: 1024px) and (max-width: 1535px)', () => {
    let monthTopInOfView = 0;
    let monthTopOutOfView = 0;

    gsap
      .timeline({
        scrollTrigger,
      })
      .eventCallback('onUpdate', () => {
        // Function to check which months are in and out of view
        monthsToWatch.forEach((month, i) => {
          if (!ref.current) return;
          const monthTop = month.getBoundingClientRect().top;
          const monthIndex = i + 1;
          // The month top goes out of the months container at the top
          if (
            monthTop <= topOfInnerContainer &&
            monthTopOutOfView < monthIndex
          ) {
            monthTopOutOfView = monthIndex;
            ref.current.setAttribute(
              'data-month-top-out-of-view',
              monthIndex.toString()
            );
          }
          // The month top comes back into the months container from the top
          if (
            monthTop > topOfInnerContainer &&
            monthTopOutOfView === monthIndex
          ) {
            monthTopOutOfView = monthIndex - 1;
            ref.current.setAttribute(
              'data-month-top-out-of-view',
              (monthIndex - 1).toString()
            );
          }
          if (
            // The month top comes into the months container from the bottom
            monthTop <= bottomOfInnerContainer &&
            monthTopInOfView < monthIndex
          ) {
            monthTopInOfView = monthIndex;
            ref.current.setAttribute(
              'data-month-top-in-of-view',
              monthIndex.toString()
            );
          }
          if (
            // The month top goes out of the months container at the bottom
            monthTop > bottomOfInnerContainer &&
            monthTopInOfView === monthIndex
          ) {
            monthTopInOfView = monthIndex - 1;
            ref.current.setAttribute(
              'data-month-top-in-of-view',
              (monthIndex - 1).toString()
            );
          }
        });
      })
      .to(
        months[0],
        {
          marginTop: -months[0].offsetHeight + bottomOfInnerContainer,
          ease: 'none',
          duration: 1,
        },
        0
      )
      .to(
        months[1],
        {
          marginTop: -months[1].offsetHeight - bottomAfterHeight,
          ease: 'none',
          duration: 1,
        },
        1
      )
      .to(
        months[2],
        {
          marginTop: -months[2].offsetHeight,
          ease: 'none',
          duration: 1,
        },
        2
      );
  });

  /* Desktop animation */
  mm.add('(min-width: 1536px)', () => {
    let monthTopInOfView = 0;
    let monthTopOutOfView = 0;

    gsap
      .timeline({
        scrollTrigger,
      })
      .eventCallback('onUpdate', () => {
        monthsToWatch.forEach((month, i) => {
          if (!ref.current) return;
          const monthTop = month.getBoundingClientRect().top;
          const monthIndex = i + 1;
          if (
            monthTop <= topOfInnerContainer &&
            monthTopOutOfView < monthIndex
          ) {
            monthTopOutOfView = monthIndex;
            ref.current.setAttribute(
              'data-month-top-out-of-view',
              monthIndex.toString()
            );
          }
          if (
            monthTop > topOfInnerContainer &&
            monthTopOutOfView === monthIndex
          ) {
            monthTopOutOfView = monthIndex - 1;
            ref.current.setAttribute(
              'data-month-top-out-of-view',
              (monthIndex - 1).toString()
            );
          }
          if (
            monthTop <= bottomOfInnerContainer &&
            monthTopInOfView < monthIndex
          ) {
            monthTopInOfView = monthIndex;
            ref.current.setAttribute(
              'data-month-top-in-of-view',
              monthIndex.toString()
            );
          }
          if (
            monthTop > bottomOfInnerContainer &&
            monthTopInOfView === monthIndex
          ) {
            monthTopInOfView = monthIndex - 1;
            ref.current.setAttribute(
              'data-month-top-in-of-view',
              (monthIndex - 1).toString()
            );
          }
        });
      })
      .to(
        months[1],
        {
          marginTop: -months[0].offsetHeight,
          ease: 'power2.out',
          duration: 1,
        },
        0
      )
      .to(
        months[2],
        {
          marginTop: -months[1].offsetHeight,
          ease: 'power2.out',
          duration: 1,
        },
        1
      );
  });
}

export default heroAnimation;
