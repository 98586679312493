import gql from 'graphql-tag';

import { mapLocationItem } from '../overview-fragment';

const mapSectionFragment = gql`
  fragment mapSectionFields on MapSection {
    title
    subtitle
    text
    cta {
      ...ctaFields
    }
    locations {
      ...mapLocationItem
    }
  }
  ${mapLocationItem}
`;

export default mapSectionFragment;
