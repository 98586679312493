import Icon from '@atoms/Icon/Icon';
import { FilterCollection, FilterEvent } from '@hooks/useFilters';
import CheckboxInput from '@molecules/CheckboxInput/CheckboxInput';
import IndeterminateCheckboxInput from '@molecules/CheckboxInput/IndeterminateCheckboxInput';
import { FilterGroup } from '@type-declarations/filters';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './Filters.module.scss';

interface Props {
  group: FilterGroup;
  isActive: (groupKey: keyof FilterCollection, filterId: string) => boolean;
  onChange: (event: FilterEvent) => void;
  activeFilters: FilterCollection;
  hideSubSubCategories?: boolean;
}

function getCount(
  activeFilters: FilterCollection,
  group: FilterGroup,
  hideSubSubCategories?: boolean
) {
  if (group.key === 'categories') {
    if (hideSubSubCategories) {
      return group.filters.reduce((acc, filter) => {
        if (
          filter.filters?.some(({ value }) =>
            activeFilters.categories.includes(value)
          )
        ) {
          return acc + 1;
        }

        return acc;
      }, 0);
    }

    let count = 0;
    activeFilters.categories.forEach(category => {
      group.filters.forEach(filter => {
        count += (filter.filters || []).filter(
          subFilter => subFilter.value === category
        ).length;
      });
    });
    return count;
  }

  if (group.key !== 'tags' && group.key !== 'accessibility') {
    return 0;
  }

  return activeFilters[group.key].length;
}

export default function Fieldset({
  group,
  isActive,
  onChange,
  activeFilters,
  hideSubSubCategories = false,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [itemHeight, setItemHeight] = useState<string>('0');
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setItemHeight(`${itemRef?.current?.offsetHeight || 0}px`);
  }, [isExpanded]);

  /* Return null when no filters are available */
  if (!group.filters.length) {
    return null;
  }

  const handleClick = () => {
    setIsExpanded(state => !state);
  };

  const style = { '--item-height': itemHeight } as React.CSSProperties;
  const count = getCount(activeFilters, group, hideSubSubCategories);

  return (
    <fieldset
      className={styles.filterGroup}
      key={`secondary-filter-group-${group.key}-${group.title}`}
    >
      <legend className={styles.filterGroupTitle}>
        <button
          onClick={handleClick}
          className={styles.filterGroupButton}
          aria-expanded={isExpanded}
          aria-controls={`${group.key}-panel`}
          type="button"
        >
          <span className={styles.filterGroupButtonText}>{group.title}</span>
          <span className={styles.iconContainer} aria-hidden="true">
            {count > 0 && (
              <span className={styles.filterCount} aria-hidden="true">
                {count}
              </span>
            )}
            <Icon
              className={styles.svgIcon}
              icon={isExpanded ? 'MINUS' : 'PLUS'}
            />
          </span>
        </button>
      </legend>
      <div id={`${group.key}-panel`} style={style}>
        <CSSTransition
          in={isExpanded}
          timeout={300}
          unmountOnExit
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
        >
          <div className={styles.filterGroupContent}>
            <div className={styles.filterGroupContentWrapper} ref={itemRef}>
              {group.filters.map(filter =>
                filter.filters?.length ? (
                  <IndeterminateCheckboxInput
                    id={`secondary-filter-${filter.value}`}
                    key={`secondary-filter-${filter.value}`}
                    name={group.key}
                    label={filter.title}
                    value={filter.value}
                    onChange={values => {
                      // @ts-expect-error - TODO: Categories group is not supported in our Typescript yet.
                      onChange({ type: group.key, value: values });
                    }}
                    checkboxes={filter.filters.map(subFilter => ({
                      id: subFilter.value,
                      name: group.key,
                      label: subFilter.title,
                      value: subFilter.value,
                      checked: isActive(group.key, subFilter.value),
                    }))}
                    hideOptions={hideSubSubCategories}
                  />
                ) : (
                  <CheckboxInput
                    id={`secondary-filter-${filter.value}`}
                    key={`secondary-filter-${filter.value}`}
                    name={group.key}
                    label={filter.title}
                    value={filter.value}
                    checked={isActive(group.key, filter.value)}
                    onChange={() =>
                      // @ts-expect-error - TODO: Categories group is not supported in our Typescript yet.
                      onChange({ type: group.key, value: filter.value })
                    }
                  />
                )
              )}
            </div>
          </div>
        </CSSTransition>
      </div>
    </fieldset>
  );
}
