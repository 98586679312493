import Button from '@atoms/Button/Button';
import { ROUTES } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import SearchInput from '@molecules/SearchInput/SearchInput';
import { FormEvent, useState } from 'react';

import styles from './SearchForm.module.scss';

interface Props {
  id?: string;
  onSubmit: ((query: string) => void) | null;
  initialQuery: string | null;
  withButton?: boolean;
}

export default function SearchForm({
  id,
  onSubmit,
  initialQuery,
  withButton = true,
}: Props) {
  const {
    store: { locale },
  } = useStore();

  const { search: searchTranslation, searchLabel: searchLabelTranslation } =
    useTranslations();

  const [query, setQuery] = useState(initialQuery);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (!onSubmit) return;

    event.preventDefault();
    if (!query) return;
    onSubmit(query);
  };

  return (
    <form
      id={id}
      action={ROUTES.SearchPage[locale]}
      method="GET"
      role="search"
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <SearchInput
        name="query"
        label={searchLabelTranslation}
        defaultValue={query || ''}
        onChange={value => setQuery(value)}
        modifier={withButton ? 'withButton' : null}
        debounceTimerInMs={0}
      />

      {withButton && (
        <Button
          type="submit"
          label={searchTranslation}
          variant="brand"
          hideLabel
          icon="SEARCH"
          className={styles.submit}
        />
      )}
    </form>
  );
}
