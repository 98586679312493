import clsx from 'clsx';

import styles from './AlertBar.module.scss';

interface Props {
  text: string;
  backgroundColor: 'brand' | 'primary' | 'secondary';
}

export default function AlertBar({ text, backgroundColor }: Props) {
  return (
    <div className={clsx(styles.bar, styles[backgroundColor])}>
      <div className="u-container-width">{text}</div>
    </div>
  );
}
