import { fetchPrepr } from '@lib/prepr/prepr';
import EVENT_PAGE_QUERY from '@lib/queries/eventPage';
import EVENT_PAGES_QUERY from '@lib/queries/eventPages';
import { Event } from '@type-declarations/overviewItems';
import { EventPage } from '@type-declarations/page';
import { QueryParameters } from '@type-declarations/prepr';
import { prepareDateForQuery } from '@utils/filters/dateFilter';

export interface EventPagesResponse {
  data: {
    EventPages: {
      items: Event[];
      total: number;
    };
  };
}

export interface EventPageResponse {
  data: {
    EventPage: EventPage;
  };
}

/* Event pages */
export async function getEventPages({
  page,
  title,
  tags,
  categories,
  date,
  locale,
  preview,
}: QueryParameters) {
  const res = await fetchPrepr<EventPagesResponse>({
    query: EVENT_PAGES_QUERY({
      filterCategories: !!categories?.length,
      filterTags: !!tags?.length,
      filterTitle: !!title,
    }),
    variables: {
      page,
      title,
      tags,
      categories,
      locale,
      preview,
      ...prepareDateForQuery(date),
    },
  });

  return {
    items: res?.data?.EventPages.items || [],
    total: res?.data?.EventPages.total || 0,
  };
}

/* Event page */
export async function getEventPage({
  id,
  slug,
  locale,
  preview,
}: QueryParameters) {
  const res = await fetchPrepr<EventPageResponse>({
    query: EVENT_PAGE_QUERY,
    variables: { id, slug, locale, preview },
  });

  return res?.data?.EventPage;
}
