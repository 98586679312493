import useTranslations from '@hooks/useTranslations';
import CardsGridWrapper from '@molecules/CardsGridWrapper/CardsGridWrapper';
import Loader from '@molecules/Loader/Loader';
import Pagination, {
  Props as PaginationProps,
} from '@molecules/Pagination/Pagination';
import {
  Event,
  Location,
  News,
  OverviewItem,
  Route,
} from '@type-declarations/overviewItems';
import { RefObject } from 'react';

interface Props extends PaginationProps {
  loading?: boolean;
  items: (Location | Event | News | Route | OverviewItem)[];
  scrollToRef: RefObject<HTMLDivElement>;
  alternateGrid?: boolean;
  showType?: boolean;
  notFoundTitle?: string;
  notFoundText?: string;
  total: number;
}

function CardGrid({
  loading,
  items,
  total,
  showType,
  scrollToRef,
  alternateGrid,
  notFoundTitle,
  notFoundText,
  currentPage,
  totalPages,
  hasPrevPage,
  hasNextPage,
  handlePrevPage,
  handleNextPage,
  handleGoToPage,
}: Props) {
  const { nothingFoundTextFilter } = useTranslations();

  if (loading) {
    return <Loader loading={loading} modifier="verticalPadding" />;
  }

  return (
    <>
      <CardsGridWrapper
        total={total}
        items={items}
        alternateGrid={alternateGrid}
        showType={showType}
        scrollToRef={scrollToRef}
        notFoundTitle={notFoundTitle}
        notFoundText={notFoundText || nothingFoundTextFilter}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleGoToPage={handleGoToPage}
      />
    </>
  );
}

export default CardGrid;
