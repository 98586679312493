import { ROUTES } from '@constants/constants';
import useStore from '@hooks/useStore';
import { RoutePage } from '@type-declarations/page';
import { TouristTrip, WithContext } from 'schema-dts';

export default function RouteSchema({ page }: { page: RoutePage }) {
  const {
    store: { locale },
  } = useStore();

  const schema: WithContext<TouristTrip> = {
    '@type': 'TouristTrip',
    '@context': 'https://schema.org',
    name: page.title,
    description: page.introText,
    url: `${
      process.env.ENVIRONMENT_URL + ROUTES.LocationOverviewPage[locale]
    }/${page.slug}`,
    image: page.image?.url,
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
}
