import { useOverview } from '@hooks/useOverview';
import useStore from '@hooks/useStore';
import { getRoutePages } from '@lib/prepr/route';
import Filters from '@molecules/Filters/Filters';
import CardGrid from '@organisms/CardGrid/CardGrid';
import Hero from '@organisms/Hero/Hero';
import Sections from '@organisms/Sections/Sections';
import { FilterGroup } from '@type-declarations/filters';
import { RouteOverviewPage } from '@type-declarations/page';
import { groupCategoryFilters } from '@utils/filters/categoryFilter';
import { tagToFilter } from '@utils/filters/tagFilter';
import { formatDistanceRange } from '@utils/formatDistance';
import { useRef } from 'react';

interface Props {
  page: RouteOverviewPage;
}

const translations = {
  nl: {
    searchLabel: 'Zoek naar routes',
    tags: 'Kenmerken',
    categories: 'Categorieën',
    nothingFoundTitle: 'Er zijn geen routes gevonden',
    nothingFoundText: 'Pas uw filter input aan of probeer het later nog eens.',
  },
  en: {
    searchLabel: 'Search for routes',
    tags: 'Tags',
    categories: 'Categories',
    nothingFoundTitle: 'No routes found',
    nothingFoundText: 'Try changing your filter input or try again later.',
  },
};

export default function RoutesOverviewTemplate({ page }: Props) {
  const {
    store: { locale },
  } = useStore();

  const scrollToRef = useRef<HTMLDivElement | null>(null);

  const {
    title,
    introText,
    image,
    items: initItems,
    total: initTotal,
    alignment,
  } = page;
  const {
    items,
    total,
    activeFilters,
    clearFilters,
    handleChange,
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
    loading,
  } = useOverview({ initItems, initTotal, scrollToRef, fetch: getRoutePages });

  const categoryGroups: FilterGroup[] = groupCategoryFilters(page.categories);

  const primaryTags = tagToFilter(page.primaryTags);

  return (
    <>
      <Hero
        title={title}
        text={introText}
        image={image}
        colorVariant="gray"
        alignment={alignment}
      />

      <Filters
        primary={[
          { key: 'tags', filters: primaryTags },
          {
            key: 'distance',
            filters: page.distances.map(distance => ({
              value: `${distance.from}-${distance.to}`,
              title: formatDistanceRange(distance.from, distance.to),
            })),
          },
        ]}
        secondary={[
          ...categoryGroups,
          {
            title: translations[locale].tags,
            key: 'tags',
            filters: [...primaryTags, ...tagToFilter(page.extraTags)],
          },
        ]}
        activeFilters={activeFilters}
        searchLabel={translations[locale].searchLabel}
        onChange={handleChange}
        onClear={clearFilters}
      />

      <CardGrid
        total={total}
        loading={loading}
        notFoundTitle={translations[locale].nothingFoundTitle}
        notFoundText={translations[locale].nothingFoundText}
        items={items}
        scrollToRef={scrollToRef}
        currentPage={currentPage}
        totalPages={totalPages}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleGoToPage={handleGoToPage}
      />

      <Sections sections={page.sections} />
    </>
  );
}
