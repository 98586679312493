import { ALTERNATE_ITEMS_PER_PAGE } from '@constants/constants';
import { getContentItems } from '@lib/prepr/content-item';
import { OverviewItem } from '@type-declarations/overviewItems';
import { useRouter } from 'next/router';
import { RefObject, useState } from 'react';

import useDidMountEffect from './useDidMountEffect';
import usePagination from './usePagination';
import useStore from './useStore';

interface Props {
  initItems: OverviewItem[];
  initTotal: number;
  scrollToRef: RefObject<HTMLDivElement | null>;
}

export function useContentItems({
  initItems,
  initTotal = 0,
  scrollToRef,
}: Props) {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const {
    store: { locale },
  } = useStore();

  const [data, setData] = useState({
    items: initItems,
    total: initTotal,
  });

  const [fetchTimeout, setFetchTimeout] = useState<NodeJS.Timeout | null>(null);

  const fetchItems = async ({ page }: { page: number }) => {
    setLoading(true);
    const { items, total } = await getContentItems({
      exploreTags: [
        router.query.period as string,
        router.query.companion as string,
        router.query.transport as string,
        router.query.weather as string,
      ].filter(tag => !!tag),
      page,
      locale,
      limit: ALTERNATE_ITEMS_PER_PAGE,
      preview: false,
    });
    setLoading(false);
    setData({
      items,
      total,
    });
  };

  const {
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
  } = usePagination({
    total: data.total,
    fetchMore: page => fetchItems({ page }),
    scrollToRef,
    limit: ALTERNATE_ITEMS_PER_PAGE,
  });

  useDidMountEffect(() => {
    if (fetchTimeout) {
      clearTimeout(fetchTimeout);
      setFetchTimeout(null);
    }

    setFetchTimeout(
      setTimeout(() => {
        fetchItems({ page: Number(router.query.page || 1) });
      }, 200)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  return {
    ...data,
    loading,
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
  };
}
