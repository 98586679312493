import Button from '@atoms/Button/Button';
import RichText from '@atoms/RichText/RichText';
import { Table, Tbody, Td, Tr } from '@atoms/Table/Table';
import { BG_DEFAULT, TIMETABLE_ID } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import AccessibilitySection from '@organisms/AccessibilitySection/AccessibilitySection';
import { Cta } from '@type-declarations/cta';
import { StoreModifier } from '@type-declarations/modifier';
import { PreprDate } from '@type-declarations/prepr';
import capitalizeFirstLetter from '@utils/capitalizeFirstLetter';
import formatDate from '@utils/formatDate';
import clsx from 'clsx';
import { useState } from 'react';

import styles from './EventInfo.module.scss';

interface Props {
  text?: string;
  price?: string;
  cta?: Cta;
  guideDog?: boolean;
  hearingImpaired?: boolean;
  general?: boolean;
  visuallyImpaired?: boolean;
  walkingDifficulties?: boolean;
  dates?: PreprDate[];
  modifier?: StoreModifier;
}

const today = new Date().toISOString();
const FOLD_COUNT = 5;

export default function EventInfo({
  dates,
  text,
  cta,
  price,
  guideDog,
  hearingImpaired,
  general,
  visuallyImpaired,
  walkingDifficulties,
  modifier,
}: Props) {
  const [futureDates] = useState(
    dates?.filter(({ until }) => until >= today) || []
  );
  const [showCount, setShowCount] = useState(FOLD_COUNT);
  const {
    store: { locale },
  } = useStore();
  const translations = useTranslations();

  const getTimeLabel = ({
    from,
    until,
  }: {
    from: string;
    until: string;
  }): string => {
    const times = {
      from: from.substring(11, 16),
      until: until.substring(11, 16),
    };

    /* Event open entire day */
    if (
      (times.from === '00:00' && times.until === '23:59') ||
      (times.from === '23:59' && times.until === '23:59')
    ) {
      return translations.allDay;
    }

    /* Event has no end time */
    if (from === until || times.until === '23:59') {
      return from.substring(11, 16);
    }

    /* Show opening times */
    return `${times.from} - ${times.until}`;
  };

  if (!futureDates.length) return null;

  return (
    <>
      <Section
        containerClassName={clsx(modifier && styles[modifier])}
        backgroundColor={BG_DEFAULT}
        id={TIMETABLE_ID}
      >
        {modifier !== 'inStore' && (
          <SectionContent
            centered
            title={translations.information}
            text={text}
            cta={cta}
          />
        )}
        {!!dates?.length && (
          <div className={styles.container}>
            <h3 className={clsx('u-typography-heading-4', styles.datesTitle)}>
              {translations.when}
            </h3>
            <Table>
              <Tbody>
                {futureDates.map(({ from, until }, i) => (
                  <Tr
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${from} + ${i}`}
                    className={clsx(i >= showCount && styles.underFold)}
                  >
                    <Td>
                      {capitalizeFirstLetter(
                        formatDate({
                          date: from,
                          format: 'cccc d MMM Y',
                          locale,
                        })
                      )}
                    </Td>
                    <Td>{getTimeLabel({ from, until })}</Td>
                  </Tr>
                ))}
                {futureDates.length > showCount && (
                  <Tr className={styles.lastRow}>
                    <Td colSpan={2} align="center" className={styles.lastRow}>
                      <Button
                        label={translations.showDates}
                        variant="white"
                        onClick={() =>
                          setShowCount(count => count + FOLD_COUNT)
                        }
                      />
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </div>
        )}
        {!!price && (
          <div className={styles.container}>
            <h3 className="u-typography-heading-4">{translations.price}</h3>
            <Table>
              <Tbody>
                <Tr>
                  <Td>
                    <RichText text={price} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </div>
        )}
      </Section>
      <AccessibilitySection
        backgroundColor={{ color: BG_DEFAULT }}
        typename="AccessibilitySection"
        guideDog={!!guideDog}
        hearingImpaired={!!hearingImpaired}
        general={!!general}
        visuallyImpaired={!!visuallyImpaired}
        walkingDifficulties={!!walkingDifficulties}
        id="event-a11y-section"
      />
    </>
  );
}
