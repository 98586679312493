export const seoFields = `
  seo {
    metaTitle: meta_title
    metaDescription: meta_description
    ogImage: og_image {
      id: _id
      url(width: 1280)
    }
  }
`;
