import useTranslations from '@hooks/useTranslations';
import { Address } from '@type-declarations/location';
import { StoreModifier } from '@type-declarations/modifier';
import { RoutePoint } from '@type-declarations/prepr';
import clsx from 'clsx';

import RouteEndItem from './RouteEndItem';
import RouteItem from './RouteItem';
import styles from './RouteTimeline.module.scss';

interface Props {
  stops?: RoutePoint[];
  start?: Address;
  end?: Address;
  modifier?: StoreModifier;
}

export default function RouteTimeline({
  stops = [],
  start,
  end,
  modifier,
}: Props) {
  const { start: startLabel, end: endLabel } = useTranslations();

  return (
    <div className={clsx(styles.wrapper, modifier && styles[modifier])}>
      <div className={clsx('u-container', styles.container)}>
        <ul className={clsx(styles.content, 'u-list-clean')}>
          <RouteEndItem title={startLabel} address={start} />

          {stops.map((stop, index) => (
            <RouteItem
              stop={stop}
              key={stop.id}
              index={index}
              modifier={modifier}
            />
          ))}

          <RouteEndItem title={endLabel} address={end} />
        </ul>
      </div>
    </div>
  );
}
