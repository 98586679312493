import { ROUTES } from '@constants/constants';
import useStore from '@hooks/useStore';
import { LocationPage } from '@type-declarations/page';
import { Place, WithContext } from 'schema-dts';

export default function LocationSchema({ page }: { page: LocationPage }) {
  const {
    store: { locale },
  } = useStore();

  const schema: WithContext<Place> = {
    '@type': 'Place',
    '@context': 'https://schema.org',
    name: page.title,
    description: page.introText,
    url: `${
      process.env.ENVIRONMENT_URL + ROUTES.LocationOverviewPage[locale]
    }/${page.slug}`,
    image: page.image?.url,
    telephone: page.phone,
    address: {
      '@type': 'PostalAddress',
      streetAddress: page.address?.street,
      addressLocality: page.address?.city,
      postalCode: page.address?.zipcode,
      addressCountry: page.address?.country,
      telephone: page.phone,
      email: page.mail,
      url: page.website,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: page.coordinates?.latitude,
      longitude: page.coordinates?.longitude,
    },
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
}
