function setUrlSearchParams({
  router,
  params,
  replace = false,
  shallow = true,
  pathname = '',
}) {
  // Construct query params from object.
  const query = Object.fromEntries(
    Object.entries(params).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, value]) => {
        if (value === null || value === '' || value === false) return false;
        if (Array.isArray(value) && value.length === 0) return false;

        return true;
      }
    )
  );

  const parameters = { pathname: pathname || router.pathname, query };

  const options = { scroll: false, shallow };

  if (replace) {
    router.replace(parameters, undefined, options);
    return;
  }

  router.push(parameters, undefined, options);
}

export default setUrlSearchParams;
