import Button from '@atoms/Button/Button';
import { TIMETABLE_ID } from '@constants/constants';
import useStore from '@hooks/useStore';
import { ContactDetails } from '@type-declarations/contactDetails';
import { Locale } from '@type-declarations/locale';
import { Address, Coordinates } from '@type-declarations/location';
import { PreprDate } from '@type-declarations/prepr';
import { formatAddress } from '@utils/formatAddress';
import formatDate from '@utils/formatDate';
import { formatPhone } from '@utils/formatPhone';

import styles from './HeroCtas.module.scss';

const translations = {
  mail: {
    nl: 'Stuur een e-mail',
    en: 'Send an e-mail',
  },
  websiteLabel: {
    nl: 'Bezoek de website',
    en: 'Visit website',
  },
  until: {
    nl: 't/m',
    en: 'until',
  },
  openNav: {
    nl: 'Open navigatie',
    en: 'Open navigation',
  },
};

const getDateLabel = (dates: PreprDate[], locale: Locale) => {
  if (dates.length > 1) {
    return `${translations.until[locale]} ${formatDate({
      date: dates.at(-1)!.until,
      format: 'dd MMMM Y',
      locale,
    })}`;
  }

  return formatDate({
    date: dates[0]!.from,
    format: 'dd MMMM Y',
    locale,
  });
};

function getLocationLabel(locale: Locale, address?: Address) {
  if (address?.title) return address.title;

  if (address) {
    return formatAddress(address);
  }

  return translations.openNav[locale];
}

function getMapsUrl({
  address,
  coordinates,
}: {
  coordinates?: Coordinates;
  address?: Address;
}) {
  if (address?.city && address?.street && address?.zipcode) {
    return `www.google.com/maps/search/?api=1&query=${formatAddress({
      ...address,
      joinChar: '+',
    })}`;
  }

  if (coordinates) {
    return `www.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}`;
  }

  return 'www.google.com/maps';
}

interface Props extends ContactDetails {
  location?: {
    coordinates?: Coordinates;
    address?: Address;
  };
  dates?: PreprDate[];
}

export default function HeroCtas({
  location,
  phone,
  mail,
  website,
  dates,
}: Props) {
  const {
    store: { locale },
  } = useStore();

  return (
    <div className={styles.ctasContainer}>
      {(location?.coordinates || location?.address) && (
        <Button
          label={getLocationLabel(locale, location.address)}
          iconLeft="MAP_PIN"
          icon="NAV_ARROW_RIGHT"
          variant="white"
          fullWidth
          href={getMapsUrl(location)}
        />
      )}
      {phone && (
        <Button
          label={phone}
          iconLeft="PHONE"
          icon="NAV_ARROW_RIGHT"
          variant="white"
          fullWidth
          href={`tel:${formatPhone({ phone })}`}
        />
      )}
      {mail && (
        <Button
          label={translations.mail[locale]}
          iconLeft="MAIL_OUT"
          icon="NAV_ARROW_RIGHT"
          variant="white"
          fullWidth
          href={`mailto:${mail}`}
        />
      )}
      {website && (
        <Button
          label={translations.websiteLabel[locale]}
          iconLeft="LINK"
          icon="NAV_ARROW_RIGHT"
          variant="white"
          fullWidth
          href={website}
        />
      )}
      {!!dates?.length && (
        <Button
          label={getDateLabel(dates, locale)}
          iconLeft="CALENDAR"
          icon="NAV_ARROW_RIGHT"
          variant="white"
          fullWidth
          href={`#${TIMETABLE_ID}`} // link to time table in content
        />
      )}
    </div>
  );
}
