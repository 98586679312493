import OpeningTimesTable from '@atoms/OpeningTimesTable/OpeningTimesTable';
import LocationSchema from '@atoms/schemas/locationSchema';
import {
  BG_ALTERNATIVE,
  BG_DEFAULT,
  EVENTS_ON_LOCATION,
} from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import AccessibilitySection from '@organisms/AccessibilitySection/AccessibilitySection';
import ContentCarouselSection from '@organisms/ContentCarouselSection/ContentCarouselSection';
import Hero from '@organisms/Hero/Hero';
import HeroCtas from '@organisms/Hero/HeroCtas';
import HighlightedBannerSection from '@organisms/HighlightedBannerSection/HighlightedBannerSection';
import ImageSliderSection from '@organisms/ImageSliderSection/ImageSliderSection';
import InteractiveMap from '@organisms/InteractiveMap/InteractiveMap';
import Sections from '@organisms/Sections/Sections';
import TextSection from '@organisms/TextSection/TextSection';
import VideoSection from '@organisms/VideoSection/VideoSection';
import { LocationPage as Props } from '@type-declarations/page';
import { getHeroColor } from '@utils/getHeroColor';
import { findHighlightedTag } from '@utils/getHighlightedTag';
import { useMemo } from 'react';

const translations = {
  events: {
    nl: 'Evenementen',
    en: 'Events',
  },
};

function LocationTemplate({ page }: { page: Props }) {
  const {
    store: { locale },
  } = useStore();
  const {
    id,
    title,
    introText,
    image,
    phone,
    mail,
    website,
    address,
    coordinates,
    a11yGeneral,
    a11yGuideDog,
    a11yHearingImpaired,
    a11yVisuallyImpaired,
    a11yWalkingDifficulties,
    items,
    alignment,
    category,
    tags,
  } = page;
  const t = useTranslations();

  const heroColor = useMemo(() => getHeroColor({ page }), [page]);
  const highlightedTag = useMemo(() => findHighlightedTag(tags), [tags]);

  return (
    <>
      <Hero
        pageId={id}
        title={title}
        text={introText}
        image={image}
        colorVariant={heroColor}
        containerSize="narrow"
        showTopBar
        variant="small"
        alignment={alignment}
      >
        <HeroCtas
          location={{
            coordinates,
            address,
          }}
          phone={phone}
          mail={mail}
          website={website}
        />
      </Hero>
      {page.description && (
        <TextSection
          id="location-description"
          typename="TextSection"
          text={page.description}
          backgroundColor={{ color: BG_DEFAULT }}
        />
      )}
      {!!page.openingHours?.regularHours.length && (
        <OpeningTimesTable
          regularHours={page.openingHours.regularHours}
          specialHours={page.openingHours.specialHours}
          message={page.openingHoursMessage}
        />
      )}
      {page.youtubeId && (
        <VideoSection
          id="location-video"
          typename="VideoSection"
          video={{ youtubeId: page.youtubeId }}
          backgroundColor={{ color: BG_DEFAULT }}
          fullWidth={false}
        />
      )}
      {page.extraImages && (
        <ImageSliderSection
          id="location-images"
          typename="ImageSliderSection"
          images={page.extraImages}
          backgroundColor={{ color: BG_DEFAULT }}
        />
      )}
      <AccessibilitySection
        general={a11yGeneral}
        guideDog={a11yGuideDog}
        hearingImpaired={a11yHearingImpaired}
        visuallyImpaired={a11yVisuallyImpaired}
        walkingDifficulties={a11yWalkingDifficulties}
        typename="AccessibilitySection"
        id="accessibility-section"
        backgroundColor={{ color: BG_DEFAULT }}
      />

      {highlightedTag && (
        <HighlightedBannerSection
          icon={highlightedTag.icon}
          bannerTitle={
            highlightedTag.bannerTitle || t.highlightedBannerSectionTitle
          }
          bannerText={
            highlightedTag.bannerText || t.highlightedBannerSectionText
          }
          theme={highlightedTag.theme}
          bannerCta={highlightedTag.bannerCta}
        />
      )}

      {!!items?.length && (
        <ContentCarouselSection
          title={translations.events[locale]}
          selection={page.items}
          typename="ContentCarouselSection"
          id={`location-events-${page.id}`}
          backgroundColor={{ color: BG_ALTERNATIVE }}
          maxCount={EVENTS_ON_LOCATION}
        />
      )}

      {coordinates && (
        <InteractiveMap
          disablePopup
          locations={[
            {
              id: `map-pin-${id}`,
              typename: 'LocationPage',
              title,
              icon: category?.[0]?.icon || category?.[0]?.category?.[0]?.icon,
              pos: [
                Number(coordinates.latitude),
                Number(coordinates.longitude),
              ],
            },
          ]}
        />
      )}

      <Sections sections={page.sections} />

      <LocationSchema page={page} />
    </>
  );
}

export default LocationTemplate;
