import { BG_ALTERNATIVE } from '@constants/constants';
import { useContentItems } from '@hooks/useContentItems';
import CardGrid from '@organisms/CardGrid/CardGrid';
import ExploreFormSection from '@organisms/ExploreFormSection/ExploreFormSection';
import Hero from '@organisms/Hero/Hero';
import Sections from '@organisms/Sections/Sections';
import { ExplorePage } from '@type-declarations/page';
import { useRef } from 'react';

function ExploreTemplate({ page }: { page: ExplorePage }) {
  const scrollToRef = useRef<HTMLDivElement | null>(null);
  const {
    id,
    title,
    introText,
    image,
    alignment,
    sections,
    formTitle,
    formCta,
    items: initItems,
    total: initTotal,
  } = page;

  const {
    total,
    items,
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
  } = useContentItems({ initItems, initTotal, scrollToRef });

  return (
    <>
      <Hero
        pageId={id}
        title={title}
        text={introText}
        image={image}
        variant="large"
        colorVariant="primary"
        alignment={alignment}
      />
      <ExploreFormSection
        backgroundColor={{ color: BG_ALTERNATIVE }}
        ctaLabel={formCta}
        id="explore-page-nlf"
        title={formTitle}
        typename="ExploreFormSection"
      />
      <CardGrid
        total={total}
        alternateGrid
        showType
        items={items}
        scrollToRef={scrollToRef}
        currentPage={currentPage}
        totalPages={totalPages}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleGoToPage={handleGoToPage}
      />

      <Sections sections={sections} />
    </>
  );
}

export default ExploreTemplate;
