import { Address } from '@type-declarations/location';

interface Props extends Address {
  joinChar?: string;
}

export const formatAddress = ({
  street,
  streetNumber,
  streetNumberSuffix,
  zipcode,
  city,
  joinChar = ', ',
}: Props): string =>
  [
    (street || '') +
      (streetNumber ? ` ${streetNumber + (streetNumberSuffix || '')}` : ''),
    `${zipcode || ''}${zipcode && city ? ' ' : ''}${city || ''}`,
  ]
    .filter(x => !!x && x !== ' ')
    .join(joinChar);
