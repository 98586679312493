import Hero from '@organisms/Hero/Hero';
import Sections from '@organisms/Sections/Sections';
import { LandingPage } from '@type-declarations/page';

function LandingTemplate({ page }: { page: LandingPage }) {
  const { title, introText, image, alignment, cta } = page;

  return (
    <div data-theme={page.theme}>
      <Hero
        title={title}
        text={introText}
        image={image}
        // If there is a video, uncomment the following line and add correct mp4 files
        video={{
          mobile: '/video/landingspage-hero-mobile.mp4',
          desktop: '/video/landingspage-hero-desktop.mp4',
        }}
        variant="large"
        cta={cta}
        alignment={alignment}
      />

      <div className="u-mt-12@from-lg">
        <Sections sections={page.sections} />
      </div>
    </div>
  );
}

export default LandingTemplate;
