import { ROUTES } from '@constants/constants';
import useStore from '@hooks/useStore';
import { Location } from '@type-declarations/overviewItems';
import { LocationOverviewPage } from '@type-declarations/page';
import { ItemList, ListItem, WithContext } from 'schema-dts';

export default function LocationOverviewSchema({
  items,
  page,
}: {
  items: Location[];
  page: LocationOverviewPage;
}) {
  const {
    store: { locale },
  } = useStore();

  const schema: WithContext<ItemList> = {
    '@type': 'ItemList',
    '@context': 'https://schema.org',
    name: page.title,
    description: page.introText,
    url: `${process.env.ENVIRONMENT_URL + ROUTES.LocationOverviewPage[locale]}`,
    image: page.image?.url,
    itemListElement: items.map(
      (item, index) =>
        ({
          '@type': 'ListItem',
          position: index + 1,
          item: {
            '@type': 'Place',
            name: item.title,
            description: item.introText,
            url: `${
              process.env.ENVIRONMENT_URL + ROUTES.LocationOverviewPage[locale]
            }/${item.slug}`,
            image: item.image?.url,
            telephone: item.phone,
            address: {
              '@type': 'PostalAddress',
              streetAddress: item.address?.street,
              addressLocality: item.address?.city,
              postalCode: item.address?.zipcode,
              addressCountry: item.address?.country,
              telephone: item.phone,
              email: item.mail,
              url: item.website,
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: item.coordinates?.latitude,
              longitude: item.coordinates?.longitude,
            },
          },
        } as ListItem)
    ),
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
}
