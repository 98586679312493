import { getContentItems } from '@lib/prepr/content-item';
import { getStoreGroupItems } from '@lib/prepr/store/exploreGroupItems';
import { OverviewItem } from '@type-declarations/overviewItems';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import useStore from './useStore';

export default function useSharedBookmarks() {
  const {
    store: { locale },
  } = useStore();
  const { asPath, query } = useRouter();
  const [loading, setLoading] = useState<boolean>(true);

  const [{ items, total }, setBookmarkItems] = useState<{
    items: OverviewItem[];
    total: number;
  }>({ items: [], total: 0 });

  const fetchBookmarks = useCallback(async () => {
    const getByID = async (ids: string[]) => {
      try {
        const result = await getContentItems({ idAny: ids, limit: 500 });

        setBookmarkItems(result);
      } catch (error) {
        console.error('Error fetching items:', error);
        setBookmarkItems({ items: [], total: 0 });
      } finally {
        setLoading(false);
      }
    };

    const getByInterest = async (itts: string[]) => {
      try {
        const result = await getStoreGroupItems({ locale, slugs: itts });

        setBookmarkItems({ items: result, total: result.length });
      } catch (error) {
        console.error('Error fetching items:', error);
        setBookmarkItems({ items: [], total: 0 });
      } finally {
        setLoading(false);
      }
    };

    const { id, itt }: { id?: string | string[]; itt?: string | string[] } =
      query;

    if (!id && !itt) {
      setBookmarkItems({
        items: [],
        total: 0,
      });
      return;
    }

    setLoading(true);

    if (id) {
      getByID(typeof id === 'string' ? [id] : id);
    } else if (itt) {
      getByInterest(typeof itt === 'string' ? [itt] : itt);
    }
  }, [asPath]);

  useEffect(() => {
    fetchBookmarks();
  }, []);

  return {
    loading,
    items,
    total,
    fetchBookmarks,
  };
}
