import assetFragment from '@lib/fragments/asset-fragment';
import { routeItem } from '@lib/fragments/overview-fragment';
import gql from 'graphql-tag';

interface Props {
  filterCategories?: boolean;
  filterTags?: boolean;
  filterTitle?: boolean;
}

const ROUTE_PAGES_QUERY = ({
  filterCategories,
  filterTags,
  filterTitle,
}: Props) => gql`
  query ROUTE_PAGES_QUERY(
    ${filterTitle ? '$title: String' : ''}
    ${filterCategories ? '$categories: [String!]' : ''}
    ${filterTags ? '$tags: [String!]' : ''}
    $limit: Int
    $skip: Int
    $locale: String
    $distanceGte: Int
    $distanceLte: Int
  ) {
    RoutePages(
      where: {
        ${filterCategories ? 'category: { _slug_any: $categories }' : ''}
        ${filterTags ? 'content_tags: { _slug_any: $tags }' : ''}
        distance_gte: $distanceGte
        distance_lte: $distanceLte
        _or: [
          ${
            filterTitle
              ? `
            { title_contains: $title }
            { content_tags: { title_contains: $title } }
          `
              : ''
          }
        ]
      }
      limit: $limit
      skip: $skip
      locale: $locale
    ) {
      items {
        ...routeItem
      }
      total
    }
  }
  ${assetFragment}
  ${routeItem}
`;

export default ROUTE_PAGES_QUERY;
