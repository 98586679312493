import { IndeterminateCheckboxEventOption } from '@molecules/CheckboxInput/IndeterminateCheckboxInput';
import { isRangeFilter, RangeFilter } from '@type-declarations/filter';
import { asArray } from '@utils/asArray';
import {
  convertToDateFilter,
  normalizeDateFilter,
} from '@utils/filters/dateFilter';
import {
  convertToDistanceFilter,
  normalizeDistanceFilter,
} from '@utils/filters/distanceFilter';
import setUrlSearchParams from '@utils/setUrlSearchParams';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

export interface FilterCollection {
  tags: string[];
  categories: string[];
  accessibility: string[];
  contentTypes: string[];
  title: string | null;
  query: string | null;
  date: RangeFilter | null;
  distance: RangeFilter | null;
  bookmarked: boolean;
}

interface DefaultFilterEvent {
  type:
    | 'title'
    | 'tags'
    | 'accessibility'
    | 'distance'
    | 'query'
    | 'contentTypes';
  value: string;
}

interface DateFilterEvent {
  type: 'date';
  value: RangeFilter | string;
}

interface CategoryFilterEvent {
  type: 'categories';
  value: IndeterminateCheckboxEventOption[];
}

interface BookmarkFilterEvent {
  type: 'bookmarked';
  value: boolean;
}

export type FilterEvent =
  | DefaultFilterEvent
  | DateFilterEvent
  | CategoryFilterEvent
  | BookmarkFilterEvent;

interface Props {
  callback?: (filterCollection: FilterCollection) => void;
}

export default function useFilters({ callback }: Props) {
  const router = useRouter();

  // useMemo so we don't update each time we update the url
  const initialFilters: FilterCollection = useMemo(
    () => ({
      theme: asArray(router.query.theme),
      tags: asArray(router.query.tags),
      categories: asArray(router.query.categories),
      accessibility: asArray(router.query.accessibility),
      contentTypes: asArray(router.query.contentTypes),
      title: typeof router.query.title === 'string' ? router.query.title : null,
      query: typeof router.query.query === 'string' ? router.query.query : null,
      date: normalizeDateFilter(router.query.date),
      distance: normalizeDistanceFilter(router.query.distance),
      bookmarked: router.query.bookmarked === 'true',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [filters, setFilters] = useState(initialFilters);

  const handleChange = ({ type, value }: FilterEvent) => {
    let newFilters;

    switch (type) {
      case 'tags':
      case 'accessibility':
      case 'contentTypes':
        if (filters[type].includes(value)) {
          newFilters = {
            ...filters,
            [type]: filters[type].filter(item => item !== value),
          };
        } else {
          newFilters = { ...filters, [type]: [...filters[type], value] };
        }
        break;
      case 'title':
      case 'query':
        newFilters = { ...filters, [type]: value };
        break;
      case 'date':
        newFilters = {
          ...filters,
          date: isRangeFilter(value)
            ? value
            : convertToDateFilter(value, filters.date),
        };
        break;
      case 'distance':
        newFilters = {
          ...filters,
          distance: convertToDistanceFilter(value, filters.distance),
        };
        break;
      case 'categories':
        // eslint-disable-next-line no-case-declarations
        const newCategories = [...filters.categories];
        value.forEach(category => {
          if (category.checked && !newCategories.includes(category.id)) {
            newCategories.push(category.id);
          }

          if (!category.checked && newCategories.includes(category.id)) {
            newCategories.splice(newCategories.indexOf(category.id), 1);
          }
        });

        newFilters = {
          ...filters,
          categories: newCategories,
        };
        break;
      case 'bookmarked':
        newFilters = { ...filters, bookmarked: value };
        break;
      default:
        return;
    }

    if (!newFilters) return;

    setFilters(newFilters);
    setUrlSearchParams({
      params: { slug: router.query.slug, ...newFilters },
      router,
      replace: true,
    });

    if (!callback) return;
    callback(newFilters);
  };

  const clearFilters = () => {
    const newFilters = {
      tags: [],
      categories: [],
      accessibility: [],
      contentTypes: [],
      title: null,
      query: filters.query,
      date: null,
      distance: null,
      bookmarked: filters.bookmarked,
    };

    setFilters(newFilters);
    setUrlSearchParams({
      params: { slug: router.query.slug, ...newFilters },
      router,
      replace: true,
    });

    if (!callback) return;
    callback(newFilters);
  };

  return { filters, handleChange, clearFilters };
}
